import React from 'react';
import Loader from "../../../components/Loader";
import {Card, CardBody} from "reactstrap";
import Chart from "react-apexcharts";

const CashFlowSummary = ({loading, averagePurchaseValue, discountDistory, profitHistory}) => {

  const [profit, setProfit] = React.useState([]);
  const [average, setAverage] = React.useState([]);
  const [discount, setDiscount] = React.useState([]);
  const [dates, setDates] = React.useState([]);

  React.useEffect(() => {
    if (averagePurchaseValue && discountDistory && profitHistory) {
      let date = Object.keys(profitHistory).map(key => {
        let d = String(key).split('/');
        return `${d[0]}/${d[1]}/${d[2]}`;
      });

      let profitValues = Object.keys(profitHistory).map(key => profitHistory[key]);
      let averageValues = Object.keys(averagePurchaseValue).map(key => averagePurchaseValue[key]);
      let discountValues = Object.keys(discountDistory).map(key => discountDistory[key]);

      setDates(date);
      setProfit(profitValues);
      setAverage(averageValues);
      setDiscount(discountValues);
    }
  }, [averagePurchaseValue, discountDistory, profitHistory])

  let getCategories = () => {
    let density = 15; // categories shown on the graph

    let categories = []

    let increment = density / dates.length
    let sum = 0
    let lastAddedNum = 0

    dates.forEach((e, i) => {
      let split = e.split('/');
      let formattedDate = `${split[0]}/${split[1]}`

      sum += increment

      if (i === 0 || i === dates.length-1) {
        categories.push(formattedDate)
      }else if(parseInt(sum) !== lastAddedNum) {
        categories.push(formattedDate)
        lastAddedNum = parseInt(sum)
      }else{
        categories.push("")
      }
    })
    return categories
  }

  const options = {
    chart: {
      id: "basic-bar"
    },
    dataLabels: {
      enabled: profit.length <= 31,
    },
    xaxis: {
      categories: getCategories()
    },
    stroke: {
      curve: 'smooth'
    },
    colors: ['#825ee4', '#5e72e4', '#ccc'],
    animations: {
      enabled: true,
      easing: 'easeout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      },
    },
  };

  const series = [
    {
      name: "Lucro",
      data: profit,
    },
    {
      name: "Ticket médio",
      data: average,
    },
    {
      name: "Descontos",
      data: discount,
    }
  ];

  return (
    <Card >
      <Loader show={loading} />
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-3">
          Visão geral
        </h1>
        <br />
        <Chart
          series={series}
          options={options}
          type="area"
          width="100%"
          height="280px"
        />
      </CardBody>
    </Card>
  );
};

export default CashFlowSummary;
