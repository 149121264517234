import React, { useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import {authLogout, storeLogout} from '../../services/auth';

export default function Menu() {
  const [isOpen, setIsOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const logout = async () => {
    await authLogout();
    await storeLogout();
    setRedirect(true);
  }

  return (
    <Navbar color="white" light expand="md" className="mb-4">
      {redirect && <Redirect to="/login" />}
      <Container>
        <NavbarBrand href="/">NGShop</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink className="text-danger" href="#" onClick={() => logout()}>Sair</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
