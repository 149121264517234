import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Row, Col, Button} from 'reactstrap';

import {Body, CardLogin, CardTitle, CardDivider} from '../Login/styles';

export default function Confirmed() {
  return (
    <Body>
      <Container>
        <Row>
          <Col md="5" className="my-5 mx-auto">
            <CardLogin style={{position: 'relative'}}>
              <CardTitle className="text-success">Sucesso!</CardTitle>
              <CardDivider />
              <p>Você confirmou sua conta com êxito!</p>
              <Button color="primary" to="/login" tag={Link} className="mt-3">Fazer login</Button>
            </CardLogin>
          </Col>
        </Row>
      </Container>
    </Body>
  );
}