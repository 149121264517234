import React, {useState, useEffect} from 'react';
import 'datejs';

import api from '../../../services/api';
import {getStoreToken} from '../../../services/auth';

import ProductSellMetric from "./productDynamicMetric";
import ProductMetricSummary from "./productMetricSummary";


export default function ProductMetrics() {

    let firstDayOfMonth = Date.today().clearTime().moveToFirstDayOfMonth();
    let firstDayOfMonthISOFormat = firstDayOfMonth.toString("ddMMyyyy");

    let lastDayOfMonth = Date.today().clearTime().moveToLastDayOfMonth();
    let lastDayOfMonthISOFormat = lastDayOfMonth.toString("ddMMyyyy");

    const [loading, setLoading] = useState(true);

    const [dataset, setDataset] = useState(null);
    const [dates, setDates] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [sumMetrics, setSumMetrics] = useState([]);
    const [series, setSeries] = useState({});

    useEffect(() => {
        async function get() {
            await api.get(`/shop/product/metrics/sell/${firstDayOfMonthISOFormat}/${lastDayOfMonthISOFormat}`, {
                headers: {
                    "Authorization": `Bearer ${getStoreToken()}`,
                },
            }).then(result => {
                setDataset(result.data);
            });
        }

        get();
    }, []);

    useEffect(() => {
        let generateISOFormattedDatesBetweenFirstAndLastDay = () => {
            let dates = [];
            let current = firstDayOfMonth.clone();
            while (current <= lastDayOfMonth) {
                dates.push(current.toISOString().split("T")[0]);
                current.setDate(current.getDate() + 1);
            }
            return dates;
        };
        let monthDays = generateISOFormattedDatesBetweenFirstAndLastDay();
        setDates(monthDays);
    }, [dataset]);

    useEffect(() => {
        if(dataset == null) {
            return;
        }
        let calculateSumMetrics = (dataset) => {
            let perProductMetricsObj = {};
            for (let data of dataset) {
                if(!perProductMetricsObj[data.productId]) {
                    perProductMetricsObj[data.productId] = {
                        productId: data.productId,
                        productName: data.productName,
                        count: 0,
                        uniqueCount: 0,
                        value: 0,
                    };
                }
                perProductMetricsObj[data.productId] = {
                    productId: data.productId,
                    productName: data.productName,
                    count: perProductMetricsObj[data.productId].count + data.count,
                    uniqueCount: perProductMetricsObj[data.productId].uniqueCount + data.uniqueCount,
                    value: perProductMetricsObj[data.productId].value + data.value,
                }
            }
            let perProductMetrics = [];
            for (let productId of Object.keys(perProductMetricsObj)) {
                let productMetrics = perProductMetricsObj[productId];
                if(productMetrics.count > 0) {
                    perProductMetrics.push(productMetrics);
                }
            }
            return perProductMetrics.sort((a,b) => {
                return b.value - a.value;
            });
        };

        let productMetrics = calculateSumMetrics(dataset);

        let selected = [];
        for (let product of productMetrics.slice(0, 5)) {
            selected.push(product.productId)
        }
        setSelectedProducts(selected);

        setSumMetrics(productMetrics);
        setLoading(false)
    }, [dataset]);

    useEffect(() => {
        console.log("a");
        if(dataset == null || selectedProducts == null) {
            // setLoading(true);
            return;
        }

        if(!dataset.length) {
            setSeries({});
            setLoading(false);
            return;
        }

        let groupByProduct = (dataset) => {
            let perProductDataset = {};
            for (let data of dataset) {
                if(!perProductDataset[data.productId]) {
                    perProductDataset[data.productId] = [];
                }
                perProductDataset[data.productId].push(data);
            }
            return perProductDataset;
        };

        let filterBySelected = (dataset) => {
            let selectedDatasets = {};
            for (let productId of Object.keys(dataset)) {
                if(selectedProducts.indexOf(productId) > -1) {
                    selectedDatasets[productId] = dataset[productId];
                }
            }
            return selectedDatasets;
        };


        let extractPropertiesToChart = (dataset, properties) => {
            let result = [];
            for (let product of Object.keys(dataset)) {
                result.push({
                    name: dataset[product][0]['productName'],
                    data: dataset[product].map((obj) => {
                        return {
                            x: obj.date,
                            y: obj[properties]
                        }
                    })
                });
            }
            return result;
        };

        let extractPropertiesAverageToChart = (dataset, toDivide, dividend) => {
            let result = [];
            for (let product of Object.keys(dataset)) {
                result.push({
                    name: dataset[product][0]['productName'],
                    data: dataset[product].map((obj) => {
                        return {
                            x: obj.date,
                            y: parseFloat((obj[dividend] && obj[dividend] > 0 ? obj[toDivide] / obj[dividend] : obj[toDivide])).toFixed(2)
                        }
                    })
                });
            }
            return result;
        };

        let fillBlankValues = (series) => {
            let filled = [];
            for (let product of series) {
                let foundDates = [];
                for (let day of product.data) {
                    foundDates.push(day.x);
                }

                for (let monthDay of dates) {
                    if(foundDates.indexOf(monthDay) < 0) {
                        product.data.push({
                            x: monthDay,
                            y: 0
                        });
                    }
                }
                filled.push(product);
            }
            return filled;
        };

        let perProductDataset = groupByProduct(dataset);
        perProductDataset = filterBySelected(perProductDataset);

        let value = fillBlankValues(extractPropertiesToChart(perProductDataset, 'value'));
        let count = fillBlankValues(extractPropertiesToChart(perProductDataset, 'count'));
        let uniqueCount = fillBlankValues(extractPropertiesToChart(perProductDataset, 'uniqueCount'));
        let valueAverage = fillBlankValues(extractPropertiesAverageToChart(perProductDataset, 'value', 'count'));
        let countAverage = fillBlankValues(extractPropertiesAverageToChart(perProductDataset, 'count', 'uniqueCount'));

        setSeries({
            value,
            count,
            uniqueCount,
            valueAverage,
            countAverage,
        });

        console.log(selectedProducts);

        setLoading(false);
    }, [selectedProducts]);

    return (
        <>
            <ProductSellMetric dates={dates} loading={loading} series={series}/>
            <ProductMetricSummary loading={loading} productSummaries={sumMetrics} showcaseProducts={selectedProducts}
                toggleProduct={(id) => {
                    let selected = [].concat(selectedProducts);
                    if(selected.indexOf(id) > -1) {
                        selected = selected.filter((current) => {
                            return current !== id;
                        })
                    }else{
                        selected.push(id);
                        console.log(id + " added");
                    }
                    setSelectedProducts(selected);
                    console.log(id + " updated", selectedProducts);
                }}
            />
        </>
    );
}
