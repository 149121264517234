import React from 'react';
import {Spinner} from 'reactstrap';

import {Overlay} from './styles';

export default function Loader({show, border}) {
  return (
    <Overlay show={show} style={{borderRadius: `${border}px`}}>
      <Spinner color="primary" size="lg" />
    </Overlay>
  );
}
