import React, {useState, useEffect} from 'react';
import {Card, CardBody, Row, Col, Button} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';
import Loader from '../../components/Loader';

export default function NewsCreate() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [alert, setAlert] = useState('');
  const [submit, setSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(submit) {
      const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

      if(title === '') 
        return setAlert('title');

      if(author === '') 
        return setAlert('author');

      setLoading(true);

      async function auth() {
        await api.post('/shop/news', { 
          title, 
          author,
          content,
        }, {
          headers: { 
            "Authorization": `Bearer ${getStoreToken()}` 
          } 
        }).then(() => {
          setLoading(false);
          setRedirect(true);
        });

        setSubmit(false);
      }

      if(title !== '' && author !== '') {
        auth();
      }
    }
  }, [submit, title, author, editorState]);

  return (
    <Card className="mb-5">
      <Loader show={loading} />
      {redirect && <Redirect to="/news/manager" />}
      <CardBody>
          <h1 className="h3 font-weight-light m-0 mb-4">
            Crie uma nova notícia
          </h1>
          <Row>
            <Col md="8">
              <label>Título da notícia</label>
              <input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
              {alert === 'title' && <span className="text-danger">Informe o título</span>}
            </Col>
            <Col md="4">
              <label>Autor</label>
              <input className="form-control" value={author} onChange={(e) => setAuthor(e.target.value)} />
              {alert === 'author' && <span className="text-danger">Informe o autor</span>}
            </Col>
            <Col md="12">
              <br />
              <Editor
                editorState={editorState}
                onEditorStateChange={(e) => setEditorState(e)}
              />
              <br />
              <Button 
                color="primary"
                size="lg"
                onClick={() => setSubmit(true)}>
                Publicar notícia
              </Button>
            </Col>
          </Row>
      </CardBody>
    </Card>
  );
}
