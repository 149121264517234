import React, {useState, useEffect} from 'react';
import {Card, CardBody, Row, Col, Button, CustomInput, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import classnames from 'classnames';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';
import Loader from '../../components/Loader';

import {get as getCategories} from '../../helpers/categories';
import {getInstalleds} from '../../helpers/plugins';
import Action from '../../components/Action';

export default function ProductsEdit({match}) {
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [categoryId, setCategoryId] = useState('null');
  const [price, setPrice] = useState(0);
  const [argument, setArguments] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [alert, setAlert] = useState('');

  const [submit, setSubmit] = useState(false);

  const [categories, setCategories] = useState([]);
  const [plugins, setPlugins] = useState([]);
  const [navActive, setNavActive] = useState('PAID');

  const [actionTrigger, setActionTrigger] = useState('PAID');
  const [actionPlugin, setActionPlugin] = useState('null');
  const [actionPluginIndex, setActionPluginIndex] = useState('null');
  const [actionPluginActions, setPluginActions] = useState([]);
  const [actionPluginActionsId, setPluginActionsId] = useState(null);
  const [actionPluginActionsIndex, setPluginActionsIndex] = useState('null');
  const [actionPluginActionsParameters, setPluginActionsParameters] = useState([]);
  const [actionToSave, setActionToSave] = useState({trigger: null, plugin: null, type: null});
  const [editIndex, setEditIndex] = useState(null)
  const [actions, setActions] = useState([]);
  const [deleteAction, onDeleteAction] = useState(null);
  const [editAction, onEditAction] = useState(null);

  const [saveAction, submitSaveAction] = useState(false);

  useEffect(() => {
    async function get() {
      var result = await api.get(`/shop/product/${match.params.id}`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`
        }
      });

      const {actions, arguments: argument, category_id, description, icon, name, price} = result.data;
      
      const blocksFromHtml = htmlToDraft(description);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

      setName(name);
      setPrice(price);
      setImage(icon);
      setCategoryId(category_id);
      setArguments(argument);
      setActions(JSON.parse(actions));
      setEditorState(EditorState.createWithContent(contentState));

      console.log(JSON.parse(actions));
    }

    get();
  }, [match]);

  useEffect(() => {

    var newAction = actionToSave;
    
    newAction['trigger'] = actionTrigger;
    newAction['plugin'] = actionPlugin;
    newAction['type'] = actionPluginActionsId;
    
    if(saveAction) {
      var newState = actions;
      newState.push(newAction)
      
      submitSaveAction(false);
      setActions(newState);

      setActionToSave({trigger: null, plugin: null, type: null});
      delete actionToSave['actionPluginActionsParameters'];
    }

  }, [saveAction, actionToSave, actions, actionTrigger, actionPlugin, actionPluginActionsId]);

  useEffect(() => {
    if(submit) {
      const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

      if(name === '') 
        return setAlert('name');

      if(image === '') 
        return setAlert('image');

      if(categoryId === '') 
        return setAlert('categoryId');

      if(price === '') 
        return setAlert('price');

      setLoading(true);

      var category = null;

      if(categoryId != null) {
        category = {
          id: categoryId
        }
      }

      async function set() {
        var filtered = actions.filter(Boolean);

        await api.put(`/shop/product/${match.params.id}`, { 
          name, 
          icon: image,
          description: content,
          price: parseFloat(price),
          requireAddress: false,
          category,
          arguments: argument,
          actions: JSON.stringify(filtered)
        }, {
          headers: { 
            "Authorization": `Bearer ${getStoreToken()}` 
          } 
        }).then(() => {
          setLoading(false);
          setRedirect(true);
        });

        setSubmit(false);
      }

      if(name !== '' && image !== '' && categoryId !== '' && price !== '') {
        set();
      }
    }
  }, [submit, match, name, image, categoryId, argument, actions, price, editorState]);

  useEffect(() => {
    getCategories().then(result => {
      setCategories(result.data.content);
    });

    getInstalleds().then(result => {
      setPlugins(result.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if(editIndex !== null) {
      var arry = actionToSave; 
      
      arry[editIndex.id] = editIndex.value;

      setActionToSave(arry);
      setEditIndex(null);
    }
  }, [editIndex, actionToSave]);

  useEffect(() => {
    if(deleteAction !== null) {
      delete actions[deleteAction];
      onDeleteAction(null);
    }
  }, [deleteAction, actions]);

  useEffect(() => {
    if(editAction !== null) {
      const {index, parameter, value} = editAction;

      var arry = actions; 
      arry[index][parameter] = value;

      setActions(arry);
      onEditAction(null);
    }
  }, [editAction, actions]);

  return (
    <Card className="mb-5">
      <Loader show={loading} />
      {redirect && <Redirect to="/products" />}
      <CardBody>
          <h1 className="h3 font-weight-light m-0 mb-4">
            Editar produto
          </h1>
          <Row>
            <Col md="3">
              <label>Nome do produto</label>
              <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
              {alert === 'name' && <span className="text-danger">Informe o nome</span>}
            </Col>
            <Col md="4">
              <label>Link da imagem</label>
              <input className="form-control" value={image} onChange={(e) => setImage(e.target.value)} />
              {alert === 'image' && <span className="text-danger">Informe a imagem</span>}
            </Col>
            <Col md="3">
              <label>Categoria</label>
              <select className="form-control" value={categoryId === null ? 'null' : categoryId} onChange={(e) => {
                 setCategoryId(e.target.value)
                console.log(categoryId)
              }}>
                <option value='null'>Sem categoria</option>
                {categories.map((e, i) => (
                  <option key={`category-${i}`} value={e.id}>{e.name}</option>
                ))}
              </select>
              {alert === 'category' && <span className="text-danger">Informe a categoria</span>}
            </Col>
            <Col md="2">
              <label>Preço</label>
              <input className="form-control" value={price} onChange={(e) => {
                var value = e.target.value.replace(',', '.');
                var split = value.split('.');

                if(split.length > 2) {
                  return false;
                }

                value = value.replace(/[^0-9.]/g,'');

                setPrice(value);
              }} />
              {alert === 'price' && <span className="text-danger">Informe o preço</span>}
            </Col>
            <Col md="12">
              <br />
              <label>Descrição do seu produto</label>
              <Editor
                editorState={editorState}
                onEditorStateChange={(e) => setEditorState(e)}
              />
              <hr />
              <br />
            </Col>
            <Col md="12">
              <div className="h4 font-weight-light m-0 mb-4">
                Ações do Produto
              </div>
              {plugins.length === 0 ? (
                <div className="h6 font-weight-light">Você precisa instalar um plugin para adicionar ações</div>
              ) : (
              <div>
                <Card color="dark" className="text-white">
                  <CardBody>
                    <h5 className="mb-3 text-white font-weight-light">
                      Adicionar ação 
                    </h5>
                    <Row>
                      <Col md="4">
                        <label>Executar quando o cliente:</label>
                        <select className="form-control" value={actionTrigger} onChange={(e) => setActionTrigger(e.target.value)}>
                          <option value="PAID">Pagar</option>
                          <option value="DISPUTE_LOSS">Pedir estorno</option>
                        </select>
                      </Col>
                      <Col md="4">
                        <label>Plugin</label>
                        <select className="form-control" value={actionPlugin} onChange={(e) => {
                          var index = e.target.value;
                          var {actions, id} = plugins[index];

                          setActionPluginIndex(index);
                          setActionPlugin(id);
                          setPluginActions(actions);
                        }}>
                          <option value='null' disabled>Selecione</option>
                          {plugins.map((e, i) => (
                            <option key={`plugin-${i}`} value={i}>{e.name}</option>
                          ))}
                        </select>
                      </Col>
                      <Col md="4">
                        <label>Ação</label>
                        <select className="form-control" value={actionPluginActionsIndex} onChange={(e) => {
                          var index = e.target.value;
                          var {parameters, id} = plugins[actionPluginIndex].actions[index];

                          setPluginActionsId(id);
                          setPluginActionsIndex(index);
                          setPluginActionsParameters(parameters);

                          var newState = actionToSave;

                          parameters.forEach((el, i) => {
                            if(el.parameterType === 'TEXT') {
                              newState[el.id] = '';
                            }else{
                              newState[el.id] = false;
                            }
                          });

                          setActionToSave(newState);
                        }}>
                          <option value="null" disabled>Selecione</option>
                          {actionPluginActions.map((e, i) => (
                            <option key={`action-${i}`} value={i}>{e.name}</option>
                          ))}
                        </select>
                      </Col>
                      {actionPluginActionsParameters.map((e, i) => (
                        <Col key={`actions-parameters-${i}`}>
                          {e.parameterType === 'TEXT' ? (
                            <div className="mt-3">
                              <label>
                                <small>{e.description}</small>
                              </label>
                              <input className="form-control" value={actionToSave[e.id] || ''} onChange={(event) => setEditIndex({ id: e.id, value: event.target.value })}/>
                            </div>
                          ) : 
                          (<small>
                            <CustomInput 
                              id={`action-bool-${i}`} 
                              type="checkbox" 
                              label={e.description} 
                              className="mt-4 pt-3"
                              style={{fontSize: '12px'}} 
                              onChange={() => setEditIndex({ id: e.id, value: !actionToSave[e.id] })}
                            />
                          </small>)
                          }
                        </Col>
                      ))}
                      {actionPluginActionsParameters.length > 0 && (
                        <Col md="3" className="pt-5">
                          <Button
                            color="light"
                            block
                            onClick={() => submitSaveAction(true)}>
                            Adicionar
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
                <br />
                {actions.length === 0 && <p>Você ainda não cadastrou nenhuma ação</p>}
                {actions.length > 0 && (
                  <div>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: navActive === 'PAID', 'text-primary': navActive === 'PAID' })}
                          onClick={() => setNavActive('PAID')}
                        >
                          Quando pagar
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: navActive === 'DISPUTE_LOSS', 'text-primary': navActive === 'DISPUTE_LOSS' })}
                          onClick={() => { setNavActive('DISPUTE_LOSS'); }}
                        >
                          Quando estornar
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={navActive}>
                      <TabPane tabId="PAID">
                        <br />
                        {actions.map((e, i) => {
                          if(e.trigger === 'PAID') {
                            return (
                              <Action
                                key={`action-${i}`}
                                index={i}
                                data={e}
                                plugins={plugins}
                                onDelete={() => onDeleteAction(i)} 
                                onEdit={(parameter, value, index) => onEditAction({parameter, value, index})}
                              />
                            );
                          }

                          return null;
                        })}
                      </TabPane>
                      <TabPane tabId="DISPUTE_LOSS">
                        <br />
                        {actions.map((e, i) => {
                          if(e.trigger === 'DISPUTE_LOSS') {
                            return (
                              <Action
                                key={`action-${i}`}
                                index={i}
                                data={e}
                                plugins={plugins}
                                onDelete={() => onDeleteAction(i)} 
                                onEdit={(parameter, value, index) => onEditAction({parameter, value, index})}
                              />
                            );
                          }

                          return null;
                        })}
                      </TabPane>
                    </TabContent>
                  </div>
                )}
              </div>
              )}
            </Col>
          </Row>
          <Button 
            color="primary"
            size="lg"
            onClick={() => setSubmit(true)}
            className="mt-4">
            Atualizar produto
          </Button>
      </CardBody>
    </Card>
  );
}
