import React, {useState, useEffect} from 'react';
import {Card, CardBody, Table, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import TimeAgo from 'react-timeago';
import ptStrings from 'react-timeago/lib/language-strings/pt-br';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';
import Loader from '../../components/Loader';

const formatter = buildFormatter(ptStrings);

export default function NewsManager() {
  const [posts, setPosts] = useState([]);
  const [post, postDelete] = useState(0);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function get() {
      await api.get('/shop/news', {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }).then(result => {
        setPosts(result.data.content);
        setLoading(false);
      });
    }

    get();
  }, []);

  useEffect(() => {
    if(post !== 0) {

      setLoading(true);
      
      async function remove() {
        await api.delete(`/shop/news/${post}`, {
          headers: {
            "Authorization": `Bearer ${getStoreToken()}`
          },
        });

        setUpdate(true);
      }
      remove();
    }
  }, [post]);

  useEffect(() => {
    if(update) {
      
      async function get() {
        await api.get('/shop/news', {
          headers: {
            "Authorization": `Bearer ${getStoreToken()}`,
          }
        }).then(result => {
          setPosts(result.data.content);
          setLoading(false);
        });
      }
  
      get();
      setUpdate(false);
    }
  }, [update]);

  return (
    <Card className="mb-5">
      <Loader show={loading} />
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-4">
          Notícias publicadas
          <Button color="primary" tag={Link} to="/news/create" className="float-right">Publicar nova notícia</Button>
        </h1>
        <Table>
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Autor</th>
              <th>Publicado em</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {posts.length === 0 && (
              <tr>
                <th colSpan="5">Você ainda não fez uma publicação</th>
              </tr>
            )}
            {posts.map(e => (
              <tr key={e.id}>
                <th scope="row">{e.id}</th>
                <td>{e.title}</td>
                <td>{e.author}</td>
                <td><TimeAgo date={e.creationDate} formatter={formatter} /></td>
                <td>
                  <Button color="info" size="sm" tag={Link} to={`/news/edit/${e.id}`} className="mr-2">Editar</Button>
                  <Button color="danger" size="sm" onClick={() => postDelete(e.id)}>Deletar</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
