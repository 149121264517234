import styled from 'styled-components';

export const Shop = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all ease .3s;
  margin-bottom: 15px;
  &:hover {
    border: solid 2px #825ee4;
  }
`;

export const ShopImage = styled.img`
  height: 65px;
  border-radius: 5px;
  margin-right: 15px;
`;

export const ShopTitle = styled.div`
  width: 60%;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
`;

export const ShopButton = styled.button`
  border: none;
  background: #825ee4;
  color: #fff;
  padding: 10px 25px;
  border-radius: 8px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;