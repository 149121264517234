import React, {useEffect} from 'react';

import api from '../../services/api';

export default function Confirm({match}) {
  useEffect(() => {
    async function confirm() {
      api.get(`/user/confirm/${match.params.hash}`);
    }

    confirm();
  }, [match]);

  return <div />;
}