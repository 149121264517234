import React, {useState, useEffect} from 'react';
import {Card, CardBody, Table, Button} from 'reactstrap';
import {Link} from 'react-router-dom';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';

import Loader from '../../components/Loader';

export default function ProductsManager() {
  const [products, setProducts] = useState([]);
  const [remove, removeItem] = useState(null);

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(remove) {

      setLoading(true);
      
      async function rm() {
        await api.delete(`/shop/product/${remove}`, {
          headers: {
            "Authorization": `Bearer ${getStoreToken()}`
          },
        });

        setUpdate(true);
        removeItem(null);
      }
      
      rm();
    }
  }, [remove]);

  useEffect(() => {
    if(update) {

      setLoading(true);
      
      async function get() {
        await api.get(`/shop/product?page=${page}&pageSize=10`, {
          headers: {
            "Authorization": `Bearer ${getStoreToken()}`,
          }
        }).then(result => {
          const { content, totalPages, totalElements } = result.data;
          setProducts(content);
          setLoading(false);

          if(page === 0) {
            setTotalPages(totalPages);
            setTotalElements(totalElements);
          }

          setLoading(false);
        });
      }
  
      get();
      setUpdate(false);
    }
  }, [update, page]);

  useEffect(() => setUpdate(true), []);

  return (
    <Card className="mb-5">
      <Loader show={loading} />
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-4">
          Produtos da loja
          <Button style={{backgroundColor: 'MediumSeaGreen'}} color="primary" tag={Link} to="/products/create" className="float-right">Criar novo produto</Button>
          <Button style={{marginRight: '10px'}} color="primary" tag={Link} to="/products" className="float-right">Dashboard de Produtos</Button>
        </h1>
        <Table>
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Produto</th>
              <th>ID da Categoria</th>
              <th>Preço</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {products.length === 0 && (
              <tr>
                <th colSpan="5">Você ainda não criou produtos</th>
              </tr>
            )}
            {products.map(e => (
              <tr key={e.id}>
                <th scope="row">{e.id}</th>
                <td>{e.name}</td>
                <td>{e.category_id === null ? 'Sem categoria' : e.category_id}</td>
                <td>{e.price.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                <td>
                  <Button color="info" size="sm" tag={Link} to={`/products/edit/${e.id}`} className="mr-2">Editar</Button>
                  <Button color="danger" size="sm" onClick={() => removeItem(e.id)}>Deletar</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <p>
            Mostrando página <b>{page + 1}</b> de <b>{totalPages}</b> ({totalElements} produtos)
            {((page + 1) < (totalPages)) && (
              <Button color="primary" className="float-right ml-3" onClick={() => {
                setPage(page + 1);
                setUpdate(true);
              }}>
                Próxima página
              </Button>
            )}
            {page > 0 && (
              <Button color="primary" className="float-right" onClick={() => {
                setPage(page - 1);
                setUpdate(true);
              }}>
                Página anterior
              </Button>
            )}
          </p>
      </CardBody>
    </Card>
  );
}
