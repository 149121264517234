import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Spinner} from 'reactstrap';

import {Container} from './styles';
import {isLogged, shopSelected} from '../../services/auth';

export default function Loading() {
  const [loading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState('');

  useEffect(() => {
    if(isLogged()) {
      if(shopSelected()) {
        setRedirectTo('/dashboard');
        setLoading(false);
      }else{
        setRedirectTo('/shops');
        setLoading(false);
      }
    }else{
      setRedirectTo('/login');
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      {(!loading) && <Redirect to={redirectTo} />}
      <Spinner color="primary" size="lg" />
    </Container> 
  );
}