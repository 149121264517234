import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';

import Home from './screens/Home';
import Login from './screens/Login';
import ForgotPassword from './screens/ForgotPassword';
import ForgotPasswordEnd from './screens/ForgotPassword/end';
import Register from './screens/Register';
import Sales from './screens/Sales';
import Plugins from './screens/Plugins';
import Categories from './screens/Categories';
import CategoriesEdit from './screens/Categories/edit';
import NewsCreate from './screens/News/create';
import NewsManager from './screens/News/manager';
import ProductsCreate from './screens/Products/create';
import ProductsManager from './screens/Products/manager';
import Loading from './screens/Loading';
import Shops from './screens/Shops';
import NewsEdit from './screens/News/edit';
import Settings from './screens/Settings';
import SettingsGateway from './screens/Settings/gateways';
import Cupons from './screens/Cupons';
import CuponsEdit from './screens/Cupons/edit';
import CuponsManager from './screens/Cupons/manager';
import Sale from './screens/Sale';
import ProductsEdit from './screens/Products/edit';
import ShopsCreate from './screens/Shops/create';
import Confirmed from './screens/Confirmed';
import Confirm from './screens/Confirmed/confirm';
import ConfirmExpired from './screens/Confirmed/expired';
import ProductMetrics from "./screens/Products/metric/productMetrics";

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Loading} />
        
        <PrivateRoute path='/dashboard' component={Home} page="home" />
        <PrivateRoute path='/shops/create' component={ShopsCreate} />
        <PrivateRoute path='/shops' component={Shops} />
        <PrivateRoute path="/sales/:id" component={Sale} page="sales" />
        <PrivateRoute path="/sales" component={Sales} page="sales" />
        <PrivateRoute path="/plugins" component={Plugins} page="plugins" />
        <PrivateRoute path="/categories/edit/:id" component={CategoriesEdit} page="categories" />
        <PrivateRoute path="/categories" component={Categories} page="categories" />
        <PrivateRoute path="/cupons/manager" component={CuponsManager} page="cupons" />
        <PrivateRoute path="/cupons/edit/:id" component={CuponsEdit} page="cupons" />
        <PrivateRoute path="/cupons" component={Cupons} page="cupons" />
        <PrivateRoute path="/news/edit/:id" component={NewsEdit} page="news" />
        <PrivateRoute path="/news/create" component={NewsCreate} page="news" />
        <PrivateRoute path="/news" component={NewsManager} page="news" />
        <PrivateRoute path="/products" component={ProductMetrics} page="products" exact />
        <PrivateRoute path="/products/edit/:id" component={ProductsEdit} page="products" />
        <PrivateRoute path="/products/create" component={ProductsCreate} page="products" />
        <PrivateRoute path="/products/manager" component={ProductsManager} page="products" />
        <PrivateRoute path="/settings/gateways" component={SettingsGateway} page="settings" />
        <PrivateRoute path="/settings" component={Settings} page="settings" /> 

        <Route path='/login' component={Login} />
        <Route path='/forgot' component={ForgotPassword} />
        <Route path='/register' component={Register} />
        <Route path='/confirm/:hash' component={Confirm} />
        <Route path='/confirmed' component={Confirmed} />
        <Route path='/confirmExpired' component={ConfirmExpired} />
        <Route path='/passwordRecovery/:token' component={ForgotPasswordEnd} />
      </Switch>
    </BrowserRouter>
  );
}