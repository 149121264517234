const PAYMENT_METHOD = [{
  name: "BANK_SLIP",
  value: "Boleto"
}, {
  name: "CREDIT_CARD",
  value: "Cartão de crédito"
}, {
  name: "PIX",
  value: "Pix"
}, {
  name: "ONLINE_DEBIT",
  value: "Débito online"
}, {
  name: "COUPON",
  value: "Cupom"
}, {
  name: "MANUALLY",
  value: "Pago por um administrador"
}, {
  name: "OTHER",
  value: "Não classificado"
}, {
  name: "UNPROCESSED",
  value: "Pagamento não realizado"
}];

const PAYMENT_STATUS = [{
  name: "OPEN",
  value: "Aberto"
}, {
  name: "PROCESSING",
  value: "Cartão de crédito"
}, {
  name: "PAID",
  value: "Pago"
}, {
  name: "REJECTED",
  value: "Rejeitado"
}, {
  name: "AVALIABLE",
  value: "Pago"
}, {
  name: "CANCELLED",
  value: "Cancelado"
}, {
  name: "DISPUTE",
  value: "Entrou em disputa"
}, {
  name: "DISPUTE_LOSS",
  value: "Estornado"
}, {
  name: "UNKNOWN",
  value: "Desconhecido"
}];

export function getStatus(name) {
  var value = "unknown";

  PAYMENT_STATUS.forEach(e => {
    if(e.name === name) {
      value = e.value;
    }
  });

  return value;
}

export function getMethod(name) {
  var value = "unknown";

  PAYMENT_METHOD.forEach(e => {
    if(e.name === name) {
      value = e.value;
    }
  });

  return value;
}