import React, {useState, useEffect} from 'react';
import {Card, CardBody, Row, Col, Button} from 'reactstrap';
import {Redirect} from 'react-router-dom';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';

import Loader from '../../components/Loader';

export default function CuponsEdit({match}) {
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [value, setValue] = useState(0);
  const [couponType, setCouponType] = useState('PERCENTAGE');
  const [submit, setSubmit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    async function set() {
      await api.get(`/shop/coupon/${match.params.id}`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        },
      }).then(result => {
        const {name, code, value, couponType} = result.data;

        setName(name);
        setCode(code);
        setValue(value);
        setCouponType(couponType);

        setLoading(false);
      });
    }

    set();
  }, [match]);

  useEffect(() => {
    if(submit) {
      setLoading(true);

      async function put() {
        await api.put(`/shop/coupon/${match.params.id}`, { 
          name, 
          code,
          value,
          couponType
        }, {
          headers: { 
            "Authorization": `Bearer ${getStoreToken()}` 
          } 
        }).then(async(result) => {
          console.log(result.data);
        })
        .catch((error) => {
          console.log(error.data.message);
        });

        setSubmit(false);
        setLoading(false);
        setRedirect(true);
      }
      put();
    }
  }, [submit, name, code, value, couponType, match]);

  return (
    <Card className="mb-5">
      <Loader show={loading} />
      {redirect && <Redirect to="/cupons/manager" />}
      <CardBody>
          <h1 className="h3 font-weight-light m-0 mb-4">
            Editando um cupom...
          </h1>
          <Row>
            <Col md="8">
              <label>Identificação do cupom</label>
              <input className="form-control mb-4" value={name} onChange={(e) => setName(e.target.value)} />
              {alert === 'name' && <span className="text-danger">Informe a identificação do cupom</span>}
            </Col>
            <Col md="4">
              <label>Código</label>
              <input className="form-control mb-4" value={code} onChange={(e) => setCode(e.target.value)} />
              {alert === 'code' && <span className="text-danger">Informe o código do cupom</span>}
            </Col>
            <Col md="4">
              <label>Valor</label>
              <input className="form-control" value={value} onChange={(e) => {
                var value = e.target.value.replace(',', '.');
                var split = value.split('.');

                if(split.length > 2) {
                  return false;
                }

                value = value.replace(/[^0-9.]/g,'');

                setValue(value);
              }} />
              {alert === 'value' && <span className="text-danger">Informe o valor do cupom</span>}
            </Col>
            <Col md="8">
              <label>Tipo</label>
              <select className="form-control" value={couponType} onChange={(e) => setCouponType(e.target.value)}>
                <option value="PERCENTAGE">Porcentagem</option>
                <option value="MONEY">Valor</option>
              </select>
            </Col>
          </Row>
          <Button 
            color="primary"
            size="lg"
            onClick={() => setSubmit(true)}
            className="mt-4"
            >
            Salvar atualização
          </Button>
      </CardBody>
    </Card>
  );
}
