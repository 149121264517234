import React, {useEffect, useState} from 'react';
import {Container, Card, CardBody, Row, Col, Spinner} from 'reactstrap';
import {Redirect, Link} from 'react-router-dom';
import Shimmer from "react-shimmer-effect";

import {Shop, ShopImage, ShopTitle, ShopButton, Overlay} from './styles';
import Menu from '../../components/Menu';
import api from '../../services/api';
import {setStoreToken, getAuthToken, setStoreData, setStoreId, getStoreData, storeLogout, removeStoreData} from '../../services/auth';

export default function Shops() {
  const [loading, setLoading] = useState(true);
  const [shops, setShops] = useState([]);
  const [select, setSelect] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {

    if(getStoreData()) {
      storeLogout();
      removeStoreData();
      window.location.reload();
    }

    async function get() {
      await api.get('/shop/list', {
        headers: {
          "Authorization": `Bearer ${getAuthToken()}`
        }
      }).then(async (result) => {
        setShops(result.data);
        setLoading(false);
      })
    }

    get();

  }, []);

  const selectShop = async (id, name, image) => {
    setSelect(true);

    await api.post(`/shop/select/${id}`, {}, {
      headers: {
        "Authorization": `Bearer ${getAuthToken()}`,
      },
    }).then(async (result) => {
      const {token} = result.data;

      await setStoreToken(token);
      await setStoreData(name, image);
      await setStoreId(id);
      setRedirect(true);
    })
  }

  return (
    <>
      {redirect && <Redirect to="/dashboard" />}
      {!loading && (shops.length === 0 && <Redirect to="/shops/create" />)}
      {select && (
        <Overlay>
          <Spinner color="primary" size="lg" />
        </Overlay>
      )}
      <Menu />
      <Container>
        <Card className="p-4">
          <CardBody>
            <div className="h6 text-uppercase font-weight-light m-0 mb-4">
              Selecione sua loja
              <Link className="btn btn-primary btn-sm float-right" to="/shops/create">Criar nova loja</Link>
            </div>
            {loading && (
              <Row>
                <Col md="6">
                  <Shimmer>
                    <div style={{width: '100%', height: '95px', borderRadius: '8px'}}></div>
                  </Shimmer>
                </Col>
                <Col md="6">
                  <Shimmer>
                    <div style={{width: '100%', height: '95px', borderRadius: '8px'}}></div>
                  </Shimmer>
                </Col>
              </Row>
            )}
            <Row>
              {shops.map(e => (
                <Col md="6" key={e.id}>
                  <Shop>
                    <ShopImage src={e.icon} alt="..." />
                    <ShopTitle>{e.name}</ShopTitle>
                    <ShopButton onClick={() => selectShop(e.id, e.name, e.icon)}>Selecionar</ShopButton>
                  </Shop>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
