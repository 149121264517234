import React from 'react';
import {Card, CardBody, Col, CustomInput, Row, Table} from "reactstrap";
import Loader from "../../../components/Loader";

const ProductMetricSummary = ({productSummaries, showcaseProducts, loading, toggleProduct}) => {
    return (
        <Card className="mb-5">
            <CardBody>
                <h1 className="h3 font-weight-light m-0 mb-4">
                    Resumo
                </h1>
                <br />
                <Row>
                    <Col md="12" style={{position: 'relative'}}>
                        <Loader show={loading} />
                        <Table>
                            <thead className="thead-light">
                            <tr>
                                <th>Ver</th>
                                <th>Produto</th>
                                <th>Valor total</th>
                                <th>Valor médio</th>
                                <th>Vendas</th>
                                <th>Média por carrinho</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                productSummaries.map((productMetrics) => {
                                    return (<tr key={productMetrics.productId}>
                                        <td>
                                            <CustomInput
                                                type="checkbox"
                                                checked={showcaseProducts && showcaseProducts.indexOf(productMetrics.productId) > -1}
                                                onChange={() => {
                                                    toggleProduct(productMetrics.productId)
                                                }}
                                                id={"product-" + productMetrics.productId}
                                            />
                                        </td>
                                        <td>{productMetrics.productName}</td>
                                        <td>{'R$ ' + productMetrics.value.toFixed(2)}</td>
                                        <td>{(productMetrics.value === 0 ? "R$ " + parseFloat(0).toFixed(2) : "R$ " + parseFloat(productMetrics.value / productMetrics.count).toFixed(2))}</td>
                                        <td>{productMetrics.count}</td>
                                        <td>{parseFloat((productMetrics.count === 0 ? 0 : productMetrics.count / productMetrics.uniqueCount)).toFixed(1)}</td>
                                    </tr>);
                                })
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
};

export default ProductMetricSummary