import React, {useState} from 'react'
import Loader from "../Loader";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "../../services/api";
import {getStoreToken} from "../../services/auth";
import {Alert} from "../../screens/Login/styles";

export default function PagSeguro({installed}) {

  // states
  const [isFetching, setIsFetching] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false)

  // prioritize props instead of state
  React.useEffect(()=> {
    setIsInstalled(installed);
  }, [installed])

  const disable = async () => {
    setIsFetching(true)

    await api.delete(
      `/shop/configuration/pagseguro/application`,
      {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }
    ).then(() => {
      setIsFetching(false)
      setIsInstalled(false)
    }).catch(() => {
      setIsFetching(false)
    });
  }

  const redirectToAuthorization = async () => {
    setIsFetching(true)

    await api.get('/shop/configuration/pagseguro/application', {
      headers: {
        "Authorization": `Bearer ${getStoreToken()}`
      }
    }).then(result => {
      window.location.href = result.data.link;
      setIsFetching(false);
    });
  }

  return <div style={{position: 'relative'}}>
    <Loader show={isFetching} />
    <div className="text-center">
      <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/8/80/Logo_PagSeguro.png/1200px-Logo_PagSeguro.png"} alt="..." height="60px" className="mt-4 mb-3" />
    </div>
    {isInstalled ? (
      <Button color="danger" size="lg" block onClick={() => disable()}>{isFetching ? 'Processando...' : 'Desativar'}</Button>
    ) :  (
      <Button disabled={isFetching} color="primary" size="lg" block onClick={() => redirectToAuthorization()}>{isFetching ? 'Preparando...' : 'Ativar'}</Button>
    )}
  </div>
}