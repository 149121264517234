import React from 'react';
import {Container} from 'reactstrap';
import {Route, Redirect} from 'react-router-dom';

import Menu from '../Menu';
import StoreHeader from '../StoreHeader';
import {isLogged, shopSelected} from '../../services/auth';

export default function PrivateRoute({ page, component: Component, ...rest }) {
  return (
    <Route {...rest} render={props => (
      isLogged() ? (
        <>
          <Menu />
          <Container>
            {shopSelected() && <StoreHeader
              page={page} 
            />}
            <Component {...props} />
          </Container>
        </>
      ) : <Redirect to={{pathname: '/login', state: {from: props.location}}} />
    )} />
  );
}