import React, {useEffect, useState} from 'react';
import {Card, CardBody, Row, Col, FormGroup, Label, FormText} from 'reactstrap';
import {DatePicker} from 'reactstrap-date-picker'
import 'datejs';
import './home.scss'

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';

import TopSellingProducts from "./TopSellingProducts/TopSellingProducts";
import CashFlowSummary from "./CashFlowSummary/CashFlowSummary";

export default function Home() {
  const [loading, setLoading] = useState(true);

  const [today, setToday] = useState(0);
  const [month, setMonth] = useState(0);

  const [start, setStart] = useState(Date.today().clearTime().moveToFirstDayOfMonth().toISOString())
  const [end, setEnd] = useState(Date.today().clearTime().moveToLastDayOfMonth().toISOString())

  const [data, setData] = useState({})

  useEffect(() => {

    // protect against inputs in the wrong order
    if (new Date(start) > new Date(end)) {
      let x = start;
      setStart(end)
      setEnd(x)
      return;
    }

    let first = new Date(start).toString("ddMMyyyy");

    let last = new Date(end).toString("ddMMyyyy");

    let td = Date.today().clearTime().toString("dd/MM/yyyy");
    setLoading(true)

    api.get(`/shop/dashboard/${first}/${last}`, {
      headers: {
        "Authorization": `Bearer ${getStoreToken()}`,
      }
    }).then(result => {
      let {profitHistory} = result.data;

      Object.keys(profitHistory).forEach(key => {
        if(String(key) === td) {
          setToday(profitHistory[key]);
        }
      });

      let profitValues = Object.keys(profitHistory).map(key => profitHistory[key]);

      let monthSum = 0;
      profitValues.forEach(e => {
        monthSum+=e;
      });

      setData(result.data)
      setMonth(monthSum);
      setLoading(false);
    });

  }, [start, end]);

  const dayLabels = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
  const monthLabels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  const todayButtonLabel = 'Hoje'

  return (
    <>
      <Row>
        <Col md="6">
          <Card className="mb-4 gradient-primary p-1">
            <CardBody>
              <div className="h6 text-uppercase font-weight-light">Lucro do período</div>
              <div className="h2">{month.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</div>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card className="mb-4 gradient-primary p-1">
            <CardBody>
              <div className="h6 text-uppercase font-weight-light">Lucro de hoje</div>
              <div className="h2">{today.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</div>
            </CardBody>
          </Card>
        </Col>
        <Col md="12">
          <Card className={"DashboardPeriod"}>
            <div>
              <h3 className="h3 font-weight-light m-0">
                Selecione o período
              </h3>
            </div>
            <div className={"dateInputs"}>
              <FormGroup>
                <Label>De</Label>
                <DatePicker className={"datePicker"} dayLabels={dayLabels} monthLabels={monthLabels} todayButtonLabel={todayButtonLabel} showTodayButton={true} disabled={loading} showClearButton={false} value={start} onChange={(v,f) => setStart(v)} />
              </FormGroup>

              <FormGroup>
                <Label>Até</Label>
                <DatePicker  className={"datePicker"} dayLabels={dayLabels} monthLabels={monthLabels} todayButtonLabel={todayButtonLabel} showTodayButton={true} disabled={loading} showClearButton={false} value={end} onChange={(v,f) => setEnd(v)}/>
              </FormGroup>
            </div>
          </Card>
        </Col>
        <Col md="8">
          <CashFlowSummary loading={loading} profitHistory={data.profitHistory} averagePurchaseValue={data.averagePurchaseValue} discountDistory={data.discountDistory}/>
        </Col>
        <Col md="4">
          <TopSellingProducts loading={loading} perProductPurchases={data.perProductPurchases} />
        </Col>
      </Row>
    </>
  );
}