import React, {useState, useEffect} from 'react';

import {
  Container,
  Image,
  Title,
  Description,
  Button
} from './styles';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';
import Loader from '../Loader';

export default function Plugin({id, icon, name, description}) {
  const [loading, setLoading] = useState(true);
  const [installed, setInstalled] = useState(false);
  const [update, setUpdate] = useState(null);
  const [action, setAction] = useState(null);

  useEffect(() => {
    async function getInstalleds() {
      await api.get('/shop/plugin', {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }).then(result => {
        
        const {data} = result;

        data.forEach(e => {
          if(e.id === id) {
            setInstalled(true);
          }
        });

        setLoading(false);
      });
    }

    getInstalleds();
  }, [id, update]);

  useEffect(() => setUpdate('WHEN_START'), []);

  useEffect(() => {
    if(action !== null) {
      setLoading(true);

      if(action === 'INSTALL') {
        async function install() {
          await api.put('/shop/plugin/install', {
            id
          }, 
          {
            headers: {
              "Authorization": `Bearer ${getStoreToken()}`,
            }
          }).then(() => {
            setLoading(false);
            setInstalled(true);
            setAction(null);
          });
        }

        install();
      } else if (action === 'UNINSTALL') {
        async function uninstall() {
          await api.put('/shop/plugin/uninstall', {
            id
          }, 
          {
            headers: {
              "Authorization": `Bearer ${getStoreToken()}`,
            }
          }).then(() => {
            setLoading(false);
            setInstalled(false);
            setAction(null);
          });
        }

        uninstall();
      }
    }
  }, [action, id]);

  return (
    <Container installed={installed}>
      <Loader show={loading} />
      <Image src={icon} alt="..." />
      <Title>
        {name}
        <Description>
          {description}
        </Description>
      </Title>
      {!loading && (
        <Button installed={installed} onClick={() => setAction(installed ? 'UNINSTALL' : 'INSTALL')}> 
          {installed ? 'Desinstalar' : 'Instalar'}
        </Button>
      )}
    </Container>
  );
}