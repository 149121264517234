import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 2px ${props => props.installed ? '#825ee4' : 'rgba(0, 0, 0, 0.1)'};
  background: ${props => props.installed ? '#825ee4' : '#fff'};
  color: ${props => props.installed ? '#fff' : 'rgba(0, 0, 0, 0.5)'};
  border-radius: 8px;
  transition: all ease .3s;
  margin-bottom: 15px;
  &:hover {
    border: solid 2px #825ee4;
  }
`;

export const Image = styled.img`
  height: 65px;
  border-radius: 5px;
  margin-right: 15px;
`;

export const Title = styled.div`
  width: 60%;
  font-size: 18px;
  font-weight: 600;
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 600;
  padding-right: 10px;
  margin: 0;
`;

export const Button = styled.button`
  border: none;
  background: ${props => props.installed ? '#fff' : '#825ee4'};
  color: ${props => props.installed ? '#825ee4' : '#fff'};
  padding: 10px 25px;
  border-radius: 8px;
`;