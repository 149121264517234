import React, {useState, useEffect} from 'react';
import {Row, Col, CustomInput, Button} from 'reactstrap';

export default function Action({data, index, plugins, onDelete, onEdit}) {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    plugins.forEach(plugin => {
      var {id, actions} = plugin;

      if(data.plugin === id)
      setName(plugin.name);

      actions.forEach(action => {
        if(data.type === action.id) {
          setType(action.name);
          setParameters(action.parameters);
          setLoading(false);
        }
      });
    });
  }, [data, plugins]);

  return (
    <div style={{background: 'rgba(0, 0, 0, 0.05)', borderRadius: '5px', padding: '25px', marginBottom: '25px'}}>
      {loading ? (
        <p className="m-0">Obtendo novos dados...</p>
      ) : (
        <Row>
          <Col md="6">
            <label>Plugin</label> 
            <input className="form-control" value={name} disabled />
          </Col>
          <Col md="6">
            <label>Ação</label>
            <input className="form-control" value={type} disabled />
          </Col>
          {parameters.map((e, i) => {
                return (<Col key={`actions-parameters-${i}`}>
                  {e.parameterType === 'TEXT' ? (
                          <div className="mt-3">
                            <label>
                              <small>{e.description}</small>
                            </label>
                            <input className="form-control" value={data[e.id]} onChange={(event) => {
                              onEdit(e.id, event.target.value, index)
                            }} />
                          </div>
                      ) :
                      (
                          <small>
                            <CustomInput
                                id={`action-boolean-${index}`}
                                type="checkbox"
                                label={e.description}
                                checked={data[e.id]}
                                className="mt-4 pt-3"
                                onChange={() => {
                                  onEdit(e.id, !data[e.id], index)
                                }}
                            />
                          </small>
                      )}
                </Col>
            )
          })}
          <Col md="2" className="pt-5">
            <Button
              color="danger"
              block
              onClick={onDelete}
            >
              Remover
            </Button>
          </Col>
          <Col md="12">
            <br />
          </Col>
        </Row>
      )}
    </div>
  )
}