import React, {useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function SaleConfig({data}) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <tr>
      <th scope="row">{data.id}</th>
      <td>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Configurções</ModalHeader>
          <ModalBody>
            {JSON.parse(data.arguments).map(({name, value}, index) => (
              <p key={`config-${data.id}-{index}`}><b>{name}</b>: {value}</p>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Fechar</Button>
          </ModalFooter>
        </Modal>
        <Button size="sm" color="primary" onClick={toggle}>Abrir</Button>
      </td>
      <td>{data.shortProductResume.name}</td>
      <td>{data.perProductValue.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
      <td>{data.productAmount}</td>
      <td>{data.totalDiscountValue.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
      <td>{data.totalValue.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
    </tr>
  );
}