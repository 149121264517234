import styled from 'styled-components';

export const Body = styled.div`
  background: linear-gradient(87deg,#5e72e4,#825ee4)!important;
  min-height: 100vh;
`;

export const CardLogin = styled.div`
  background: #fff;
  padding: 3rem;
  border-radius: .375rem;
  box-shadow: 0 0 2rem 0 rgba(136,152,170,.15)!important;
  margin-top: 10vh;
`;

export const CardTitle = styled.h1`
  font-size: 28px;
  color: #121212;
  margin-bottom: 5px;
`;

export const CardDivider = styled.div`
  width: 80px;
  height: 3px;
  border-radius: 3px;
  background: linear-gradient(87deg,#5e72e4,#825ee4)!important;
  margin-bottom: 30px;
`;

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  position: absolute;
  top: -10px;
  left: 15px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  padding: 0 5px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  border: solid 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  color: #8898aa;
  transition: all ease .2s;
  &:focus {
    outline: none;
    border-color: rgba(0, 0, 0, 0.2);
  }
`;
