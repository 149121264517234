import React, {useState, useEffect} from 'react';
import {Card, CardBody, Button, CustomInput, Row, Col} from 'reactstrap';

import api from '../../services/api';
import {getStoreToken, setStoreData} from '../../services/auth';
import Loader from '../../components/Loader';

export default function Settings() {
  const [loading, setLoading] = useState(true);
  const [removeIndex, setRemoveIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null)

  const [name, setName] = useState('');
  const [icon, setIcon] = useState('');
  const [requestEmailConfirmation, setRequestEmailConfirmation] = useState(false);
  const [mainDomain, setMainDomain] = useState('');
  const [domains, setDomains] = useState([]);
  
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if(submit) {
      setLoading(true);

      async function edit() {
        await api.put('/shop/configuration', { 
          name, 
          icon,
          requestEmailConfirmation,
          mainDomain,
          domains
        }, {
          headers: { 
            "Authorization": `Bearer ${getStoreToken()}` 
          } 
        }).then((result) => {
          const {icon, name} = result.data;
          setStoreData(name, icon);
          window.location.reload();
        });

        setSubmit(false);
      }

      edit();
    }
  }, [submit, name, icon, requestEmailConfirmation, mainDomain, domains]);

  useEffect(() => {
    async function get() {
      await api.get('/shop/configuration', {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }).then(result => {
        const {data} = result;

        setName(data.name);
        setIcon(data.icon);
        setRequestEmailConfirmation(data.requestEmailConfirmation);
        setMainDomain(data.mainDomain);
        setDomains(data.domains);

        setLoading(false);
      });
    }

    get();
  }, []);

  useEffect(() => {
    if(removeIndex !== null) {
      var filtered = domains;
      filtered.splice(removeIndex, 1);

      setDomains(filtered); 
      setRemoveIndex(null);
    }
  }, [removeIndex, domains]);

  useEffect(() => {
    if(editIndex !== null) {
      var arry = domains; 
      arry.splice(editIndex.i, 1, editIndex.text);

      setDomains(arry);
      setEditIndex(null);
    }
  }, [editIndex, domains]);

  return (
    <Card className="mb-5">
      <Loader show={loading} />
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-4">
          Configurações gerais
        </h1>
        <Row>
          <Col md="8">
            <label>Nome</label>
            <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
          </Col>
          <Col md="4">
            <label>Ícone</label>
            <input className="form-control" type="link" value={icon} onChange={(e) => setIcon(e.target.value)} />
            <small className="text-muted">Recomendamos sempre usar uma imagem quadrada ;)</small>
          </Col>
          <Col md="12">
            <br />
            <CustomInput
              type="checkbox"
              id="confirmEmail"
              checked={requestEmailConfirmation}
              onChange={() => setRequestEmailConfirmation(!requestEmailConfirmation)}
              label="Requisitar confirmação de e-mail"
            />
          </Col>
        </Row>
        <h4 className="h5 font-weight-light mt-5 mb-3">Domínios</h4>
        <div>
          <label>Domínio principal</label>
          <input className="form-control" value={mainDomain} onChange={(e) => setMainDomain(e.target.value)} />
        </div>
        <h4 className="h5 font-weight-light mt-5 mb-4">
          Domínios segundários
          <Button color="dark" size="sm" className="float-right mt-1" onClick={() => setDomains([...domains, ""])}>Adicionar</Button>
        </h4>
        {domains.map((e, i) => (
          <Row key={`form-${i}`}>
            <Col md="10">
              <input className="form-control mb-3" value={e} onChange={(event) => setEditIndex({i, text: event.target.value})} />
            </Col>
            <Col md="2">
              <Button color="danger" block onClick={() => setRemoveIndex(i)}>
                Excluir
              </Button>
            </Col>
          </Row>
        ))}
        <br />
        <Button 
          color="primary"
          size="lg"
          onClick={() => setSubmit(true)}>
          Salvar
        </Button>
      </CardBody>
    </Card>
  );
}
