import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {
  FiHome,
  FiCpu,
  FiBookmark,
  FiBarChart2,
  FiBox,
  FiSettings,
  FiList,
  FiGift,
} from "react-icons/fi";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Row,
  Col,
  Button
} from 'reactstrap';

import {getStoreData, storeLogout} from '../../services/auth';

export default function StoreHeader({page}) {
  const [isOpen, setIsOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const logout = () => {
    storeLogout();
    setRedirect(true);
  };

  return (
    <Card className="mb-4 pb-0">
      {redirect && <Redirect to="/shops" />}
      <CardBody className="pb-0">
        <Row>
          <Col md="2" style={{position: 'relative'}}>
            <img src={getStoreData().image} width="100px" style={{position: "absolute", left: '50%', top: '20%', transform: "translate(-50%)"}} alt="..." />
          </Col>
          <Col md="8">
            <div className="h4 font-weight-light text-muted m-0 mt-4 pt-2">Loja</div>
            <div className="h2 font-weight-light text-dark m-0">{getStoreData().name}</div>
          </Col>
          <Col md="2">
            <Button color="dark" className="float-right" onClick={logout}>Trocar de Loja</Button>
          </Col>
        </Row>
        <br />
        <Navbar color="white" light expand="md" className="mb-4 dash-menu w-100">
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className="mx-auto">
              <NavItem active={(page === 'home')}>
                <NavLink tag={Link} to="/dashboard">
                  <FiHome /> <br /> 
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem active={(page === 'sales')}>
                <NavLink tag={Link} to="/sales">
                  <FiBarChart2 /> <br /> 
                  Compras
                </NavLink>
              </NavItem>
              <NavItem active={(page === 'news')}>
                <NavLink tag={Link} to="/news">
                  <FiBookmark /> <br /> 
                  Notícias
                </NavLink>
              </NavItem>
              <NavItem active={(page === 'categories')}>
                <NavLink tag={Link} to="/categories">
                  <FiList /> <br /> 
                  Categorias
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/products" active={(page === 'products')}>
                  <FiBox /> <br />
                  Produtos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/cupons" active={(page === 'cupons')}>
                  <FiGift /> <br />
                  Cupons
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/plugins" active={(page === 'plugins')}>
                  <FiCpu /> <br />
                  Plugins
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar active={(page === 'settings')}>
                <DropdownToggle nav>
                  <FiSettings /> <br />
                  Configurar
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to="/settings">
                    Configurações gerais
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/settings/gateways">
                    Formas de pagamento
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </CardBody>
    </Card>
  );
}