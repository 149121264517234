import React from 'react';
import {Container, Row, Col} from 'reactstrap';

import {Body, CardLogin, CardTitle, CardDivider} from '../Login/styles';

export default function ConfirmExpired() {
  return (
    <Body>
      <Container>
        <Row>
          <Col md="5" className="my-5 mx-auto">
            <CardLogin style={{position: 'relative'}}>
              <CardTitle className="text-danger">Link expirado!</CardTitle>
              <CardDivider />
              <p>Você demorou demais para confirmar sua conta!</p>
            </CardLogin>
          </Col>
        </Row>
      </Container>
    </Body>
  );
}