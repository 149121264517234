import React from 'react';
import Loader from "../../../components/Loader";
import {Card, CardBody} from "reactstrap";
import Chart from "react-apexcharts";

const TopSellingProducts = ({loading, perProductPurchases}) => {

  const [donutSeries, setDonutSeries] = React.useState([]);
  const [donutLabels, setDonutLabels] = React.useState([]);

  React.useEffect(() => {
    if(perProductPurchases) {
      let newDonutSeries = [];
      let newDonutLabels = [];

      for(let productName of Object.keys(perProductPurchases)) {
        newDonutSeries.push(perProductPurchases[productName]);
        newDonutLabels.push(productName);
      }

      if(newDonutSeries.length > 10) {
        newDonutSeries.splice(10, newDonutSeries.length - 10);
        newDonutLabels.splice(10, newDonutLabels.length - 10);
      }

      setDonutSeries(newDonutSeries);
      setDonutLabels(newDonutLabels);
    }
  }, [perProductPurchases])

  const donutOptions = {
    chartOptions: {
      labels: donutLabels
    },
    dataLabels: {
      enabled: true,
    },
    series: donutSeries,
    labels: donutLabels,
    legend: {
      show: false
    },
    plotOptions: {
      pie: {
        size: undefined,
        customScale: 1,
        offsetX: 0,
        offsetY: 0,
        expandOnClick: true,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10
        },
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: false,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              color: undefined,
              offsetY: -10
            },
            value: {
              show: false,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val
              }
            },
            total: {
              show: false,
              showAlways: false,
              label: 'Total',
              color: '#373d3f',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              }
            }
          }
        },
      }
    },
  };

  return (
    <Card className="mb-5" style={{minHeight: '100%'}}>
      <Loader show={loading} />
      <CardBody>
        <h1 className="h4 font-weight-light m-0 mb-3">
          Produtos mais vendidos
        </h1>
        <br />
        {donutSeries.length === 0 ? (
          <p className="mt-5 text-muted text-center">Você ainda não efetuou<br /> vendas este mês</p>
        ) : (
          <Chart
            series={donutSeries}
            options={donutOptions}
            type="donut"
            width="100%"
            height="280px"
          />
        )}
      </CardBody>
    </Card>
  );
};

export default TopSellingProducts;
