import React, {useState} from 'react';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import Loader from "../../../components/Loader";
import Chart from "react-apexcharts";
import "./title-select.css"



const ProductSellMetric = ({dates, series, loading}) => {

    const [metric, setMetric] = useState("value");

    const options = {
        chart: {
            id: "basic-bar"
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    if (value) {
                        return "R$ " + parseFloat(value).toFixed(2);
                    }else{
                        return "R$ " + parseFloat(0).toFixed(2);
                    }
                }
            },
        },
        xaxis: {
            type: 'datetime',
            categories: dates,
        },
        stroke: {
            curve: 'smooth'
        },
        animations: {
            enabled: true,
            easing: 'easeout',
            speed: 400,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
        },
    };

    return (
        <Card className="mb-5">
            <CardBody>
                    <select defaultValue={"value"} id="title-select" className="h3 font-weight-light m-0 mb-4" onChange={(e) => {
                        setMetric(e.target.value);
                    }}>
                        <option value="value">Valor em vendas por produto</option>
                        <option value="count">Quantidade vendida por produto</option>
                        <option value="uniqueCount">Carrinhos que continham o produto</option>
                        <option value="valueAverage">Valor médio</option>
                        <option value="countAverage">Quantidade média por carrinho</option>
                    </select>

                    <Button color="primary" tag={Link} to="/products/manager" className="float-right">Gerenciar seus produtos</Button>
                <br />
                <Row>
                    <Col md="12" style={{position: 'relative'}}>
                        <Loader show={loading} />
                        <Chart
                            series={series[metric] ? series[metric] : []}
                            options={options}
                            type="area"
                            width="100%"
                            height="295px"
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default ProductSellMetric