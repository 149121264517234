import React, {useState, useEffect} from 'react';
import {Card, CardBody, Table, Button, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import Chart from 'react-apexcharts';
import 'datejs';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';

import Loader from '../../components/Loader';

export default function Cupons() {
  const [loading, setLoading] = useState(true);
  const [couponStatsMap, setCouponStatsMap] = useState([]); 
  const [useCount, setUseCount] = useState([]);
  const [donutSeries, setDonutSeries] = useState([]);
  const [donutLabels, setDonutLabels] = useState([]);

  const [series, setSeries] = useState([]);
  const [dates, setDates] = useState([]);

  const donut_options = {
    chartOptions: {
      labels: donutLabels
    },
    dataLabels: {
      enabled: true,
    },
    series: donutSeries,
    labels: donutLabels,
    legend: {
      show: false
    },
    plotOptions: {
      pie: {
        size: undefined,
        customScale: 1,
        offsetX: 0,
        offsetY: 0,
        expandOnClick: true,
        dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10
        }, 
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: false,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              color: undefined,
              offsetY: -10
            },
            value: {
              show: false,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val
              }
            },
            total: {
              show: false,
              showAlways: false,
              label: 'Total',
              color: '#373d3f',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
              }
            }
          }
        },      
      }
    },
  };

  const options = {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: dates.map(date => {
        var split = date.split('/');
        return `${split[0]}/${split[1]}`;
      })
    },
    stroke: {
      curve: 'smooth'
    },
    animations: {
      enabled: true,
      easing: 'easeout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },   
      yaxis: {
        lines: {
          show: false
        }
      },
    },
  };

  useEffect(() => {
    var fd = Date.today().clearTime().moveToFirstDayOfMonth();
    var first = fd.toString("ddMMyyyy");

    var ld = Date.today().clearTime().moveToLastDayOfMonth();
    var last = ld.toString("ddMMyyyy");

    async function get() {
      await api.get(`/shop/dashboard/coupon/${first}/${last}`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        },
      }).then(result => {
        var {couponStatsMap, useCount, useHistory} = result.data;

        var dataStats = [];
        var dataCount = [];

        Object.keys(couponStatsMap).forEach((e) => {
          dataStats.push({
            name: e,
            totalRawValue: couponStatsMap[e].totalRawValue,
            totalDiscount: couponStatsMap[e].totalDiscount,
            totalValue: couponStatsMap[e].totalValue
          });
        });

        setCouponStatsMap(dataStats);

        Object.keys(useCount).forEach((e) => {
          dataCount.push({
            name: e,
            count: useCount[e]
          });
        });

        setUseCount(dataCount);

        var dates = [];
        var i = 0;

        Object.keys(useHistory).forEach(e => {
          i++;

          if(i === 1) {
            dates = Object.keys(useHistory[e]);
          }
        });

        var series = Object.keys(useHistory).map(e => { 

          var dt = Object.keys(useHistory[e]);
          var values = [];

          dt.forEach(d => {
            values = [...values, useHistory[e][d]];
          });

          return {
            name: e, 
            data: values
          }
        });

        let donutSeries = [];
        let donutLabels = [];

        for(let couponName of Object.keys(useCount)) {
          donutSeries.push(useCount[couponName]);
          donutLabels.push(couponName);
        }

        if(donutSeries.length > 10) {
          donutSeries.splice(10, donutSeries.length - 10);
          donutLabels.splice(10, donutLabels.length - 10);
        }

        setDonutSeries(donutSeries);
        setDonutLabels(donutLabels);

        setDates(dates);
        setSeries(series);

        setLoading(false);
      });
    }

    get();
  }, []);

  const getUses = (name) => {
    var count = 0;

    useCount.forEach(e => {
      if(e.name === name) {
        count = e.count;
      }
    });

    return count;
  }

  return (
    <Card className="mb-5">
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-4">
          Cupons de desconto
          <Button color="primary" tag={Link} to="/cupons/manager" className="float-right">Gerenciar seus cupons</Button>
        </h1>
        <br />
        <Row>
          <Col md="8" style={{position: 'relative'}}>
            <Loader show={loading} />
            <Chart 
              series={series}
              options={options}
              type="area"
              width="100%"
              height="295px"
            />
          </Col>
          <Col md="4" className="pt-3" style={{position: 'relative'}}>
            <Loader show={loading} />
            {donutSeries.length === 0 ? (
              <p className="mt-5 text-muted text-center">Ainda não há dados por aqui</p>
            ) : (
              <Chart 
                series={donutSeries}
                options={donut_options}
                type="donut"
                width="100%"
                height="280px"
              />
            )}
          </Col>
        </Row>
        <br />
        <Table>
          <thead className="thead-light">
            <tr>
              <th>Cupom</th>
              <th>Utilizações</th>
              <th>Valor total</th>
              <th>Valor do desconto</th>
              <th>Valor final</th>
            </tr>
          </thead>
          <tbody>
            {couponStatsMap.length === 0 && (
              <tr>
                <th colSpan="5">Você ainda não cadastrou um cupom</th>
              </tr>
            )}
            {couponStatsMap.map((e, i) => (
              <tr key={`s-${i}`}>
                <th scope="row">{e.name}</th>
                <td>{getUses(e.name)}</td>
                <td>{e.totalRawValue.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                <td>{e.totalDiscount.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                <td>{e.totalValue.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
