import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Button} from 'reactstrap';
import {Body, CardLogin, CardTitle, CardDivider, FormGroup, Label, Input} from './styles';

import api from '../../services/api';
import Loader from '../../components/Loader';

export default function ForgotPasswordEnd({match}) {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const [password, setPassword] = useState('');
  
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    async function send() {
      setLoading(true);

      if(password === '') 
        return alert('Informe a senha');

      var token = match.params.token;

      await api.post('/user/recovery/end', { 
        token,
        password
      }).then(() => {
        setSent(true);
      }).catch(() => {
        alert('Ocorreu um erro ao tentar trocar a senha');
      });

      setSubmit(false);
      setLoading(false);
    }

    if(submit) {
      send();
    }
  }, [submit, password, match]);

  return (
    <Body>
      <Container>
        <Row>
          <Col md="5" className="my-5 mx-auto">
            <CardLogin style={{position: 'relative'}}>
              <Loader show={loading} border={8} />
              <CardTitle>Nova senha</CardTitle>
              <CardDivider className="mb-2" />
              <p className="text-muted mb-5">Agora, basta trocar sua senha informando uma nova.</p>
              <FormGroup>
                <Label>Insira sua nova senha</Label>
                <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                {sent && <span className="text-success">Senha alterada com sucesso!</span>}
              </FormGroup>
              <br />
              <Row>
                <Col md="6">
                  <Button color="primary" size="lg" onClick={() => setSubmit(true)}>Trocar minha senha</Button>
                </Col>
              </Row>
            </CardLogin>
          </Col>
        </Row>
      </Container>
    </Body>
  );
}