import React, {useEffect, useState} from 'react';
import {Container, Card, CardBody, Row, Col, Button} from 'reactstrap';
import {Redirect} from 'react-router-dom';

import Menu from '../../components/Menu';

import api from '../../services/api';
import {getAuthToken} from '../../services/auth';
import Loader from '../../components/Loader';

export default function ShopsCreate() {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [icon, setIcon] = useState('');
  const [submit, createShop] = useState(false);

  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {

    async function store() {
      setLoading(true);

      if(id === '')
        return setAlert('id');

      if(name === '')
        return setAlert('name');

      if(icon === '')
        return setAlert('icon');

      await api.post('/shop', {
        id,
        name,
        icon
      }, {
        headers: {
          "Authorization": `Bearer ${getAuthToken()}`
        }
      }).then(() => {
        setLoading(false);
        setRedirect(true);
      });
    }

    if(submit) {
      store();
    }

  }, [submit, id, name, icon]);

  return (
    <>
      {redirect && <Redirect to="/shops" />}
      <Menu />
      <Container>
        <Card>
          <CardBody>
            <Loader show={loading} />
            <h1 className="h3 font-weight-light m-0 mb-0">
              Crie uma nova loja
            </h1>
            <h2 className="h5 font-weight-light mb-4">Insira os dados abaixo para criar uma nova loja</h2>
            <Row>
              <Col>
                <label>Identificação</label>
                <input className="form-control" value={id} onChange={(e) => {
                  var value = e.target.value;
                  setId(value.replace(' ', '_'));
                }} />
                {alert === 'id' && <span className="text-danger">Informe a identificação</span>}
              </Col>
              <Col>
                <label>Nome</label>
                <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                {alert === 'name' && <span className="text-danger">Informe o nome</span>}
              </Col>
              <Col>
                <label>Ícone</label>
                <input className="form-control" value={icon} onChange={(e) => setIcon(e.target.value)} />
                {alert === 'icon' && <span className="text-danger">Informe o link do ícone</span>}
              </Col>
            </Row>
            <Button color="primary" className="mt-3" onClick={() => createShop(true)}>Salvar & Criar</Button>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}