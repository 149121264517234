import React, {useState} from 'react'
import Loader from "../Loader";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "../../services/api";
import {getStoreToken} from "../../services/auth";
import {Alert} from "../../screens/Login/styles";

export default function Pagarme({installed}) {

  // states
  const [modal, setModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false)

  // fields
  const [accountId, setAccountId] = useState('');
  const [secretKey, setSecretKey] = useState('');

  // feedbacks
  const [error, setError] = useState("")

  // prioritize props instead of state
  React.useEffect(()=> {
    setIsInstalled(installed);
  }, [installed])

  const disable = async () => {
    setIsFetching(true)

    await api.delete(
      `/shop/configuration/pagarme/credentials`,
      {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }
    ).then(() => {
      setIsFetching(false)
      setIsInstalled(false)
    }).catch(() => {
      setIsFetching(false)
    });
  }

  const save = async () => {

    // validate inputs
    if (accountId === "" || secretKey === "") {
      setError("Você precisa preencher todos os campos para continuar")
      return
    }

    // process request
    setIsFetching(true)
    setError("")
    let data = {
      "accountId": accountId,
      "secretKey": secretKey,
    }
    await api.post(`/shop/configuration/pagarme/credentials`, data, {
      headers: {
        "Authorization": `Bearer ${getStoreToken()}`
      }
    }).then(() => {
      setIsFetching(false)
      setModal(false)
      setIsInstalled(true)

      // clear inputs
      setAccountId("")
      setSecretKey("")
    }).catch(() => {
      setError("Erro ao salvar suas credenciais!")
      setIsFetching(false)
      setModal(false)
    });
  }

  return <div style={{position: 'relative'}}>
    <Loader show={isFetching} />
    <div className="text-center">
      <img src={"https://dka575ofm4ao0.cloudfront.net/pages-transactional_logos/retina/1949/PagarMe_Logo_PRINCIPAL-02.png"} alt="..." height="60px" className="mt-4 mb-3" />
    </div>
    {isInstalled ? (
      <Button color="danger" size="lg" block onClick={() => disable()}>{isFetching ? 'Processando...' : 'Desativar'}</Button>
    ) :  (
      <Button color="primary" size="lg" block onClick={() => setModal(true)}>Ativar</Button>
    )}
    <Modal isOpen={modal} toggle={() => !isFetching && setModal(!modal)}>
      <ModalHeader toggle={() => !isFetching && setModal(!modal)}>Credenciais</ModalHeader>
      <ModalBody>
        <div className="h3 font-weight-bold">Precisamos que você pegue as credenciais de API manualmente.</div>
          <div>
          <p>1 - Acesse as configurações de sua conta</p>
          <p>2 - Acesse a aba de 'Chaves'</p>
          </div>
        {error !== "" && <Alert color="danger">
          {error}
        </Alert>}
        <div className="mb-2">
          <label>Account ID</label>
          <input id="account_id" className="form-control" value={accountId} onChange={(e) => setAccountId(e.target.value)} />
        </div>
        <div>
          <label>Secret Key</label>
          <input id="secret_key" className="form-control" value={secretKey} onChange={(e) => setSecretKey(e.target.value)} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isFetching} onClick={() => save()}>{isFetching ? 'Salvando...' : 'Salvar & Ativar'}</Button>
        <Button color="dark" disabled={isFetching} onClick={() => setModal(false)}>Fechar</Button>
      </ModalFooter>
    </Modal>
  </div>
}