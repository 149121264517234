const AUTH = 'NG@Auth';
const STORE = 'NG@Store';
const STORE_ID = 'NG@StoreId';
const STORE_DATA = 'NG@StoreData';

export const isLogged = () => {
  var token = localStorage.getItem(AUTH);

  if(token !== null)
    return true;

  return false;
};

export const shopSelected = () => {
  var token = localStorage.getItem(STORE);

  if(token !== null)
    return true;

  return false;
};

export const setAuthToken = (token) => localStorage.setItem(AUTH, token);
export const setStoreToken = (token) => localStorage.setItem(STORE, token);
export const setStoreId = (id) => localStorage.setItem(STORE_ID, id);

export const getAuthToken = () => localStorage.getItem(AUTH);
export const getStoreToken = () => localStorage.getItem(STORE);
export const getStoreId = () => localStorage.getItem(STORE_ID);

export const authLogout = () => localStorage.removeItem(AUTH);
export const storeLogout = () => localStorage.removeItem(STORE);
export const removeStoreData = () => localStorage.removeItem(STORE_DATA);

export const setStoreData = (name, image) => localStorage.setItem(STORE_DATA, JSON.stringify({
  name,
  image
}));
export const getStoreData = () => JSON.parse(localStorage.getItem(STORE_DATA));