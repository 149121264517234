import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Container, Row, Col, Button} from 'reactstrap';
import {Body, CardLogin, CardTitle, CardDivider, FormGroup, Label, Input} from './styles';

import api from '../../services/api';

import Loader from '../../components/Loader';

export default function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [submit, setSubmit] = useState(false);

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function send() {
      setAlert(null);
      setError(false);
      setSuccess(false);

      if(name === '') 
        return setAlert('name');

      if(email === '') 
        return setAlert('email');

      if(password === '') 
        return setAlert('password');

      if(confirm === '') 
        return setAlert('confirm');

      setLoading(true);

      await api.post('/user/register', {
        name,
        email,
        password,
        confirm,
        marketing: true
      }).then(() => {
        setSuccess(true);
      }).catch(() => {
        setError(true);
      });

      setLoading(false);
    }

    if(submit) {
      send();
    }
  }, [submit, name, email, password, confirm]);

  return (
    <Body>
      <Container>
        <Row>
          <Col md="5" className="mx-auto">
            <CardLogin style={{position: 'relative'}}>
              <Loader show={loading} border={8} />
              <CardTitle>Criar nova conta</CardTitle>
              <CardDivider />
              <FormGroup>
                <Label>Nome completo</Label>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                {alert === 'name' && <span className="text-danger">Informe seu nome</span>}
              </FormGroup>
              <FormGroup>
                <Label>E-mail</Label>
                <Input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                {alert === 'email' && <span className="text-danger">Informe seu e-mail</span>}
                {error && <span className="text-danger">Já existe uma conta neste e-mail!</span>}
              </FormGroup>
              <FormGroup>
                <Label>Senha</Label>
                <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                {alert === 'password' && <span className="text-danger">Informe sua senha</span>}
              </FormGroup>
              <FormGroup>
                <Label>Confirmar senha</Label>
                <Input type='password' value={confirm} onChange={(e) => setConfirm(e.target.value)} />
                {alert === 'confirm' && <span className="text-danger">Confirme sua senha</span>}
              </FormGroup>
              <br />
              {success && <span className="text-success">Conta criada com sucesso! Verifique sua caixa de e-mails</span>}
              <Row>
                <Col md="6">
                  <Button color="primary" size="lg" onClick={() => setSubmit(true)}>Criar</Button>
                </Col>
                <Col md="6 text-right">
                  <Button color="light" size="lg" tag={Link} to="/">Voltar</Button>
                </Col>
              </Row>
            </CardLogin>
          </Col>
        </Row>
      </Container>
    </Body>
  );
}