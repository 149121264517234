import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Container, Row, Col, Button} from 'reactstrap';

import {Body, CardLogin, CardTitle, CardDivider, FormGroup, Label, Input, ForgotPassword, Alert} from './styles';
import api from '../../services/api';
import {setAuthToken} from '../../services/auth';
import Loader from '../../components/Loader';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submit, setSubmit] = useState(false);
  const [alert, setAlert] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if(submit) {
      setAlert(false);
      setLoading(true);
      async function auth() {
        await api.post('/user/login', { email, password })
          .then(async(result) => {
            const {token} = result.data;
            await setAuthToken(token);
            setRedirect(true);
          })
          .catch(() => setAlert(true));
        setLoading(false);
        setSubmit(false);
      }
      auth();
    }
  }, [submit, email, password]);

  return (
    <Body>
      {redirect && <Redirect to="/shops" />}
      <Container>
        <Row>
          <Col md="5" className="my-5 mx-auto">
            <CardLogin style={{position: 'relative'}}>
              <Loader show={loading} border={8} />
              <CardTitle>Autentifique-se</CardTitle>
              <CardDivider />
              {alert && <Alert>E-mail ou senha inválido</Alert>}
              <FormGroup>
                <Label>E-mail</Label>
                <Input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>Senha</Label>
                <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                <ForgotPassword to="/forgot">Esqueci a senha</ForgotPassword>
              </FormGroup>
              <br />
              <Row>
                <Col md="6">
                  <Button color="primary" size="lg" onClick={() => setSubmit(true)}>Entrar</Button>
                </Col>
                <Col md="6 text-right">
                  <Button color="light" size="lg" tag={Link} to="/register">Criar uma conta</Button>
                </Col>
              </Row>
            </CardLogin>
          </Col>
        </Row>
      </Container>
    </Body>
  );
}