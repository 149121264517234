import React, {useState, useEffect} from 'react';
import {Card, CardBody, Row, Col} from 'reactstrap';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';

import Loader from '../../components/Loader';
import Plugin from '../../components/Plugin';

export default function Plugins() {
  const [availables, setAvailables] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    async function getAvailables() {
      await api.get('/shop/plugin/avaliable', {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }).then(result => {
        setAvailables(result.data)
        setLoading(false);
      });
    }

    getAvailables();
  }, []);

  return (
    <Card className="mb-5">
      <Loader show={loading} />
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-4">
          Plugins
        </h1>
        <Row>
          {availables.map(e => (
            <Col md="6" key={e.id}>
              <Plugin 
                key={e.id}
                id={e.id}
                icon={e.icon}
                name={e.name}
                description={e.description}
              />
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
}
