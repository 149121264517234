import React, {useState, useEffect} from 'react';
import {Card, CardBody, Table, Button, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';
import Loader from '../../components/Loader';

export default function Categories() {
  const [categories, setCategories] = useState([]);
  const [category, deleteCategory] = useState(0);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [name, setName] = useState('');
  const [icon, setIcon] = useState('');
  const [parentId, setParentId] = useState('null');
  
  const [alert, setAlert] = useState('');
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if(submit) {
      if(name === '') 
        return setAlert('name');

      if(icon === '') 
        return setAlert('icon');

      var parent = undefined;

      if(parentId !== 'null') {
        parent = {
          id: parentId
        };
      }

      setLoading(true);

      async function auth() {
        await api.post('/shop/category', { 
          name, 
          icon,
          parent
        }, {
          headers: { 
            "Authorization": `Bearer ${getStoreToken()}` 
          } 
        }).then(() => {
          setName('');
          setIcon('');
          setParentId('null');
          setUpdate(true);
          setSubmit(false);
        });
      }

      if(name !== '' && icon !== '') {
        auth();
      }
    }
  }, [submit, name, icon, parentId]);

  useEffect(() => {
    async function get() {
      await api.get('/shop/category?page=0&pageSize=10', {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }).then(result => {
        const { content, totalPages, totalElements } = result.data;

        setCategories(content);
        setTotalPages(totalPages);
        setTotalElements(totalElements);

        setLoading(false);
      });
    }

    get();
  }, []);

  useEffect(() => {
    if(category !== 0) {

      setLoading(true);
      
      async function remove() {
        await api.delete(`/shop/category/${category}`, {
          headers: {
            "Authorization": `Bearer ${getStoreToken()}`
          },
        });

        setUpdate(true);
      }
      remove();
    }
  }, [category]);

  useEffect(() => {
    if(update) {
      
      async function get() {
        await api.get(`/shop/category?page=${page}&pageSize=10`, {
          headers: {
            "Authorization": `Bearer ${getStoreToken()}`,
          }
        }).then(result => {
          setCategories(result.data.content);
          setLoading(false);
        });
      }
  
      get();
      setUpdate(false);
    }
  }, [update, page]);

  return (
    <Card className="mb-5">
      <Loader show={loading} />
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-4">
          Categorias de produtos
        </h1>
        <Row>
          <Col md="8">
            <label>Nome</label>
            <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
            {alert === 'name' && <span className="text-danger">Informe o título</span>}
          </Col>
          <Col md="4">
            <label>Ícone (link)</label>
            <input className="form-control" type="link" value={icon} onChange={(e) => setIcon(e.target.value)} />
            {alert === 'link' && <span className="text-danger">Informe o autor</span>}
          </Col>
          <Col md="12">
            <br />
            <label>Categoria pai (opcional)</label>
            <select className="form-control" onChange={(e) => setParentId(e.target.value)} defaultValue={'null'}>
              <option value="null" disabled>Nada selecionado</option>
              {categories.map(e => <option key={`cat-${e.id}`} value={e.id}>{e.name}</option>)}
            </select>
            <br />
            <Button 
              color="primary"
              size="lg"
              onClick={() => setSubmit(true)}>
              Salvar
            </Button>
          </Col>
        </Row>
        <br />
        <Table>
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Tipo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {categories.length === 0 && (
              <tr>
                <th colSpan="4">Você ainda não criou categorias</th>
              </tr>
            )}
            {categories.map(e => (
              <tr key={e.id}>
                <td>{e.id}</td>
                <td>{e.name}</td>
                <td>{(e.parent_id === null) ? 'Categoria' : `Subcategoria de #${e.parent_id}`}</td>
                <td>
                  <Button color="info" size="sm" tag={Link} to={`/categories/edit/${e.id}`} className="mr-2">Editar</Button>
                  <Button color="danger" size="sm" onClick={() => deleteCategory(e.id)}>Deletar</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <p>
            Mostrando página <b>{page + 1}</b> de <b>{totalPages}</b> ({totalElements} transações)
            {((page + 1) < (totalPages)) && (
              <Button color="primary" className="float-right ml-3" onClick={() => {
                setPage(page + 1);
                setUpdate(true);
              }}>
                Próxima página
              </Button>
            )}
            {page > 0 && (
              <Button color="primary" className="float-right" onClick={() => {
                setPage(page - 1);
                setUpdate(true);
              }}>
                Página anterior
              </Button>
            )}
          </p>
      </CardBody>
    </Card>
  );
}
