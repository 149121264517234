import React, {useState, useEffect} from 'react';
import {Card, CardBody, Table, Button, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import TimeAgo from 'react-timeago';
import ptStrings from 'react-timeago/lib/language-strings/pt-br';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';
import { getStatus } from '../../helpers/enums';

import Loader from '../../components/Loader';

const formatter = buildFormatter(ptStrings);

export default function Sales() {
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [changePassword, setChangePassword] = useState(false);

  const [submit, setSubmit] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function get() {
      await api.get('/purchase?page=0&pageSize=10', {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        },
      }).then(result => {
        const {content, totalPages, totalElements} = result.data;

        setTransactions(content);
        setTotalPages(totalPages);
        setTotalElements(totalElements);
        setLoading(false);
      });
    }

    get();
  }, []);

  useEffect(() => {
    async function get() {
      setLoading(true);

      var searchQuery = "";

      if(search !== '') {
        searchQuery = `&search=${search}`;
      }

      await api.get(`/purchase?page=${page}&pageSize=10${searchQuery}`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        },
      }).then(result => {
        const {content} = result.data;

        setTransactions(content);
        setLoading(false);
        setChangePassword(false);
      });
    }

    if(changePassword) {
      get();
    }
  }, [page, search, changePassword]);

  useEffect(() => {
    async function get() {
      setLoading(true);

      await api.get(`/purchase?page=0&pageSize=10&search=${search}`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        },
      }).then(result => {
        const {content, totalPages, totalElements} = result.data;

        setTransactions(content);
        setTotalPages(totalPages);
        setTotalElements(totalElements);
        setSubmit(false);

        setLoading(false); 
      });
    }

    if(submit) { 
      get();
    }
  }, [submit, search, page]);

  const _calc = (e) => {
    var total = 0;

    e.forEach(data => {
      total += data.totalValue;
    });

    return total;
  }

  return (
    <>
      <div className="mb-4">
        <Row>
          <Col md="10">
            <input
              className="form-search"
              placeholder="Filtre sua busca pelo nome, e-mail, cupom ou código de transação"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col md="2">
            <Button color="primary" block style={{padding: '15px'}} onClick={() => setSubmit(true)}>Pesquisar</Button>
          </Col>
        </Row>
      </div>
      <Card className="mb-5">
        <Loader show={loading} />
        <CardBody>
          <h1 className="h3 font-weight-light m-0 mb-4">
            Histórico de compras
          </h1>
          <Table>
            <thead className="thead-light">
              <tr>
                <th>Gateway</th>
                <th>Cliente</th>
                <th>E-mail</th>
                <th>Valor</th>
                <th>Data</th>
                <th>Status</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {transactions.length === 0 && (
                <tr>
                  <th colSpan="6">Você ainda não efetuou vendas em sua loja</th>
                </tr>
              )}
              {transactions.map(e => (
                <tr key={e.id} style={{fontSize: 12}}>
                  <td>{e.transaction.paymentGateway}</td>
                  <td>{e.client.name}</td>
                  <td>{e.client.email}</td>
                  <td>{_calc(e.productPurchaseInfoResumes).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                  <td><TimeAgo date={e.createdAt} formatter={formatter} /></td>
                  <td>{getStatus(e.transaction.paymentStatus)}</td>
                  <td><Button tag={Link} to={`/sales/${e.id}`} color="primary" size="sm">Abrir</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br />
          <p>
            Mostrando página <b>{page + 1}</b> de <b>{totalPages}</b> ({totalElements} transações)
            {((page + 1) < (totalPages)) && (
              <Button color="primary" className="float-right ml-3" onClick={() => {
                setPage(page + 1);
                setChangePassword(true);
              }}>
                Próxima página
              </Button>
            )}
            {page > 0 && (
              <Button color="primary" className="float-right" onClick={() => {
                setPage(page - 1);
                setChangePassword(true);
              }}>
                Página anterior
              </Button>
            )}
          </p>
        </CardBody>
      </Card>
    </>
  );
}
