import api from '../services/api';
import {getStoreToken} from '../services/auth';

export async function getAvaiables() {
  return await api.get(`/shop/plugin/avaliable`, {
    headers: {
      "Authorization": `Bearer ${getStoreToken()}`
    }
  });
}

export async function getInstalleds() {
  return await api.get(`/shop/plugin`, {
    headers: {
      "Authorization": `Bearer ${getStoreToken()}`
    }
  });
}