import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {Card, CardBody, Row, Col, Button} from 'reactstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';
import Loader from '../../components/Loader';

export default function NewsEdit({match}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [id, setId] = useState(0);
  const [title, setTitle] = useState('');
  const [creationDate, setCreationDate] = useState('');
  const [author, setAuthor] = useState('');
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    async function get() {
      await api.get(`/shop/news/${match.params.id}`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        },
      }).then(result => {
        const {id, title, author, content, creationDate} = result.data;

        const blocksFromHtml = htmlToDraft(content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        setId(id);
        setTitle(title);
        setCreationDate(creationDate);
        setAuthor(author);
        setEditorState(EditorState.createWithContent(contentState));

        setLoading(false);
      });
    }

    get();
  }, [match]);

  useEffect(() => {
    if(submit) {
      const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      setLoading(true);
      async function put() {
        await api.put(`/shop/news/${id}`, { 
          title, 
          author,
          content,
          creationDate
        }, {
          headers: { 
            "Authorization": `Bearer ${getStoreToken()}` 
          } 
        }).then(async(result) => {
          console.log(result.data);
        })
        .catch((error) => {
          console.log(error.data.message);
        });

        setSubmit(false);
        setRedirect(true);
      }
      put();
    }
  }, [submit, title, author, editorState, creationDate, id]);

  return (
    <Card className="mb-5">
      {redirect && <Redirect to="/news" />}
      <Loader show={loading} />
      <CardBody>
          <h1 className="h3 font-weight-light m-0 mb-4">
            Editando uma notícia...
          </h1>
          <Row>
            <Col md="8">
              <label>Título da notícia</label>
              <input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
            </Col>
            <Col md="4">
              <label>Autor</label>
              <input className="form-control" value={author} onChange={(e) => setAuthor(e.target.value)} />
            </Col>
            <Col md="12">
              <br />
              <Editor
                editorState={editorState}
                onEditorStateChange={(e) => setEditorState(e)}
              />
              <br />
              <Button 
                color="primary"
                size="lg"
                onClick={() => setSubmit(true)}>
                Publicar atualização
              </Button>
            </Col>
          </Row>
      </CardBody>
    </Card>
  );
}
