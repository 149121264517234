import React, {useState, useEffect} from 'react';
import {Card, CardBody, Button, Row, Col} from 'reactstrap';
import {Redirect} from 'react-router-dom';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';
import Loader from '../../components/Loader';

export default function CategoriesEdit({match}) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const [name, setName] = useState('');
  const [icon, setIcon] = useState('');
  const [parentId, setParentId] = useState('null');
  
  const [alert, setAlert] = useState('');
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if(submit) {
      if(name === '') 
        return setAlert('name');

      if(icon === '') 
        return setAlert('icon');

      var parend_id = undefined;

      if(parentId !== 'null') {
        parend_id = parentId;
      }

      setLoading(true);

      async function auth() {
        await api.put(`/shop/category/${match.params.id}`, { 
          name, 
          icon,
          parend_id,
        }, {
          headers: { 
            "Authorization": `Bearer ${getStoreToken()}` 
          } 
        }).then(() => {
          setLoading(false);
          setRedirect(true);
        }).catch((error) => console.log(error.data.message));

        setSubmit(false);
      }

      if(name !== '' && icon !== '') {
        auth();
      }
    }
  }, [submit, name, icon, parentId, match]);

  useEffect(() => {
    async function get() {
      await api.get(`/shop/category/${match.params.id}`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }).then(result => {
        
        const {name, icon, parent_id} = result.data;

        setName(name);
        setIcon(icon);
        setParentId(parent_id === null ? 'null' : parent_id);

        setLoading(false);
      });
    }

    get();
  }, [match]);

  useEffect(() => {
    async function get() {
      await api.get('/shop/category', {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }).then(result => {
        setCategories(result.data.content);
        setLoading(false);
      });
    }

    get();
  }, []);

  return (
    <Card className="mb-5">
      {redirect && <Redirect to="/categories" />}
      <Loader show={loading} />
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-4">
          Categorias de produtos
        </h1>
        <Row>
          <Col md="8">
            <label>Nome</label>
            <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
            {alert === 'name' && <span className="text-danger">Informe o título</span>}
          </Col>
          <Col md="4">
            <label>Ícone (link)</label>
            <input className="form-control" type="link" value={icon} onChange={(e) => setIcon(e.target.value)} />
            {alert === 'link' && <span className="text-danger">Informe o autor</span>}
          </Col>
          <Col md="12">
            <br />
            <label>Categoria pai (opcional)</label>
            <select className="form-control" onChange={(e) => setParentId(e.target.value)} value={parentId}>
              <option value="null" disabled>Nada selecionado</option>
              {categories.map(e => {
                if(parseInt(e.id) !== parseInt(match.params.id)) {
                  return (<option key={`cat-${e.id}`} value={e.id}>{e.name}</option>);
                }
                return null;
              })}
            </select>
            <br />
            <Button 
              color="primary"
              size="lg"
              onClick={() => setSubmit(true)}>
              Salvar
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
