import React, { useEffect, useState } from 'react';
import {Card, CardBody, Row, Col} from 'reactstrap';
import { getStoreId, getStoreToken } from '../../services/auth';
import api from '../../services/api';
import Loader from '../../components/Loader';
import PayPal from "../../components/gateways/PayPal";
import MercadoPago from "../../components/gateways/MercadoPago";
import PicPay from "../../components/gateways/PicPay";
import PagSeguro from "../../components/gateways/PagSeguro";
import Juno from "../../components/gateways/Juno";
import Pagarme from "../../components/gateways/Pagarme";
import Paghiper from "../../components/gateways/Paghiper";

export default function SettingsGateway() {
  const [loading, setLoading] = useState(true);
  const [gateways, setGateways] = useState([]);

  useEffect(() => {

    async function get() {
      await api.get(`/shop/exposed/${getStoreId()}/gateways`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }).then(result => {
        const {data} = result;

        setLoading(false);

        var array = [];
        
        array['MERCADOPAGO'] = false;
        array['PAGSEGURO'] = false;
        array['PAYPAL'] = false;
        array['PICPAY'] = false;
        array['JUNO'] = false;
        array['PAGARME'] = false;
        array['PAGHIPER'] = false;

        data.forEach(element => {
          array[element] = true;
        });

        setGateways(array);
      });
    }

    get();
  }, []);

  return (
    <Card className="mb-5">
      <Loader show={loading} />
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-4">
          Formas de Pagamento
        </h1>
        <Row>
          <Col md="4">
            <MercadoPago installed={gateways['MERCADOPAGO']} />
          </Col>
          <Col md="4">
            <PayPal installed={gateways['PAYPAL']} />
          </Col>
          <Col md="4">
            <PagSeguro installed={gateways['PAGSEGURO']} />
          </Col>
          <Col md="4">
            <PicPay installed={gateways['PICPAY']} />
          </Col>
          <Col md="4">
            <Juno installed={gateways['JUNO']} />
          </Col>
          <Col md="4">
            <Pagarme installed={gateways['PAGARME']} />
          </Col>
          <Col md="4">
            <Paghiper installed={gateways['PAGHIPER']} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
