import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Container, Row, Col, Button} from 'reactstrap';
import {Body, CardLogin, CardTitle, CardDivider, FormGroup, Label, Input} from './styles';

import api from '../../services/api';
import Loader from '../../components/Loader';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [submit, setSubmit] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function send() {
      setLoading(true);
      setError(null);

      if(email === '') 
        return setError(2);

      await api.post('/user/recovery', { email }).then(() => {
        setSent(true);
      }).catch(() => {
        setError(1);
      });

      setSubmit(false);
      setLoading(false);
    }

    if(submit) {
      send();
    }
  }, [submit, email]);

  return (
    <Body>
      <Container>
        <Row>
          <Col md="5" className="my-5 mx-auto">
            <CardLogin style={{position: 'relative'}}>
              <Loader show={loading} border={8} />
              <CardTitle>Esqueci a senha</CardTitle>
              <CardDivider className="mb-2" />
              <p className="text-muted mb-5">Informe seu email para que seja possível redefinir sua senha.</p>
              <FormGroup>
                <Label>E-mail</Label>
                <Input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                {error === 1 && <span className="text-danger">Não encontramos este e-mail</span>}
                {error === 2 && <span className="text-danger">Informe seu e-mail</span>}
                {sent && <span className="text-success">Enviamos um e-mail com as instruções para recuperar sua senha</span>}
              </FormGroup>
              <br />
              <Row>
                <Col md="6">
                  <Button color="primary" size="lg" onClick={() => setSubmit(true)}>Enviar</Button>
                </Col>
                <Col md="6 text-right">
                  <Button color="light" size="lg" tag={Link} to="/">Voltar</Button>
                </Col>
              </Row>
            </CardLogin>
          </Col>
        </Row>
      </Container>
    </Body>
  );
}