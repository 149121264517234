import api from '../services/api';
import {getStoreToken} from '../services/auth';

export async function get(id = null) {
  var query = "";

  if(id) {
    query = `/${id}`;
  }

  return await api.get(`/shop/category${query}`, {
    headers: {
      "Authorization": `Bearer ${getStoreToken()}`
    }
  });
}