import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Card, CardBody, Table, Button, Row, Col} from 'reactstrap';
  
import Loader from '../../components/Loader';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';

export default function CuponsManager() {
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [cupons, setCupons] = useState([]);

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [value, setValue] = useState(0);
  const [couponType, setCouponType] = useState('PERCENTAGE');
  const [alert, setAlert] = useState('');
  const [submit, setSubmit] = useState(false);
  const [remove, removeItem] = useState(null);

  useEffect(() => setUpdate(true), []);

  useEffect(() => {
    async function get() {
      await api.get(`/shop/coupon?page=${page}&pageSize=10`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`
        }
      }).then(result => {
        const {content, totalPages, totalElements} = result.data;
        setCupons(content);

        if(page === 0) {
          setTotalPages(totalPages);
          setTotalElements(totalElements);
        }

        setLoading(false);
        setUpdate(false);
      });
    }

    if(update) {
      get();
    }
  }, [update, page]);

  useEffect(() => {
    async function create() {
      let newAlert = '';

      if(!name) 
        newAlert = 'name';
      if(!code)
        newAlert = 'code';
      if(!value)
        newAlert = 'value';

      if(newAlert !== '') {
        setSubmit(false);
        setAlert(newAlert);
        return;
      }

      setLoading(true);

      await api.post('/shop/coupon', {
        name,
        code,
        value,
        couponType
      }, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`
        }
      }).then(() => {
        setLoading(false);
        setUpdate(true);
        setSubmit(false);
      })
    }
    
    if(submit) {
      create();
    }
  }, [submit, name, code, value, couponType]);

  useEffect(() => {
    async function purge() {
      await api.delete(`/shop/coupon/${remove}`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`
        }
      }).then(() => {
        setUpdate(true);
        removeItem(null);
        setLoading(false);
      });
    }

    if(remove) {
      setLoading(true);
      purge();
    }
  }, [remove]);

  return (
    <Card>
      <Loader show={loading} />
      <CardBody>
        <h1 className="h3 font-weight-light m-0 mb-4">
          Gerenciador de Cupons
        </h1>
        <Row>
          <Col md="8">
            <label>Identificação do cupom</label>
            <input className="form-control mb-4" value={name} onChange={(e) => setName(e.target.value)} />
            {alert === 'name' && <span className="text-danger">Informe a identificação do cupom</span>}
          </Col>
          <Col md="4">
            <label>Código</label>
            <input className="form-control mb-4" value={code} onChange={(e) => setCode(e.target.value)} />
            {alert === 'code' && <span className="text-danger">Informe o código do cupom</span>}
          </Col>
          <Col md="4">
            <label>Valor</label>
            <input className="form-control" value={value} onChange={(e) => {
              var value = e.target.value.replace(',', '.');
              var split = value.split('.');

              if(split.length > 2) {
                return false;
              }

              value = value.replace(/[^0-9.]/g,'');

              setValue(value);
            }} />
            {alert === 'value' && <span className="text-danger">Informe o valor do cupom</span>}
          </Col>
          <Col md="8">
            <label>Tipo</label>
            <select className="form-control" value={couponType} onChange={(e) => setCouponType(e.target.value)}>
              <option value="PERCENTAGE">Porcentagem</option>
              <option value="MONEY">Valor</option>
            </select>
          </Col>
        </Row>
        <Button 
          color="primary"
          size="lg"
          onClick={() => setSubmit(true)}
          className="mt-3 mb-5">
          Criar cupom
        </Button>
        <br />
        <Table>
          <thead className="thead-light">
            <tr>
              <th>Identificação</th>
              <th>Código</th>
              <th>Tipo de Desconto</th>
              <th>Valor</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {cupons.map((e, i) => (
              <tr key={i}>
                <th scope="row">{e.name}</th>
                <td>{e.code}</td>
                <td>{e.couponType === 'PERCENTAGE' ? 'Porcentagem' : 'Dinheiro'}</td>
                <td>{e.couponType === 'PERCENTAGE' ? `${e.value}%` : e.value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</td>
                <td>
                  <Button tag={Link} to={`/cupons/edit/${e.id}`} color="primary" size="sm">Editar</Button>
                  <Button onClick={() => removeItem(e.id)} color="danger" size="sm" className="ml-2">Deletar</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <p>
            Mostrando página <b>{page + 1}</b> de <b>{totalPages}</b> ({totalElements} cupons)
            {((page + 1) < (totalPages)) && (
              <Button color="primary" className="float-right ml-3" onClick={() => {
                setPage(page + 1);
                setUpdate(true);
              }}>
                Próxima página
              </Button>
            )}
            {page > 0 && (
              <Button color="primary" className="float-right" onClick={() => {
                setPage(page - 1);
                setUpdate(true);
              }}>
                Página anterior
              </Button>
            )}
          </p>
      </CardBody>
    </Card>
  );
}
