import React, {useState} from 'react'
import Loader from "../Loader";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "../../services/api";
import {getStoreToken} from "../../services/auth";
import {Alert} from "../../screens/Login/styles";

export default function MercadoPago({installed}) {

  // states
  const [modal, setModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false)

  // fields
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  // feedbacks
  const [error, setError] = useState("")

  // prioritize props instead of state
  React.useEffect(()=> {
    setIsInstalled(installed);
  }, [installed])

  const disable = async () => {
    setIsFetching(true)

    await api.delete(
      `/shop/configuration/mercadopago/credentials`,
      {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        }
      }
    ).then(() => {
      setIsFetching(false)
      setIsInstalled(false)
    }).catch(() => {
      setIsFetching(false)
    });
  }

  const save = async () => {

    // validate inputs
    if (clientId === "" || clientSecret === "") {
      setError("Você precisa preencher todos os campos para continuar")
      return
    }

    // process request
    setIsFetching(true)
    setError("")
    let data = {
      "clientId": clientId,
      "secret": clientSecret
    }
    await api.post(`/shop/configuration/mercadopago/credentials`, data, {
      headers: {
        "Authorization": `Bearer ${getStoreToken()}`
      }
    }).then(() => {
      setIsFetching(false)
      setModal(false)
      setIsInstalled(true)

      // clear inputs
      setClientId("")
      setClientSecret("")
    }).catch(() => {
      setError("Erro ao salvar suas credenciais!")
      setIsFetching(false)
      setModal(false)
    });
  }

  return <div style={{position: 'relative'}}>
    <Loader show={isFetching} />
    <div className="text-center">
      <img src={"https://logodownload.org/wp-content/uploads/2019/06/mercado-pago-logo.png"} alt="..." height="60px" className="mt-4 mb-3" />
    </div>
    {isInstalled ? (
      <Button color="danger" size="lg" block onClick={() => disable()}>{isFetching ? 'Processando...' : 'Desativar'}</Button>
    ) :  (
      <Button color="primary" size="lg" block onClick={() => setModal(true)}>Ativar</Button>
    )}
    <Modal isOpen={modal} toggle={() => !isFetching && setModal(!modal)}>
      <ModalHeader toggle={() => !isFetching && setModal(!modal)}>Credenciais</ModalHeader>
      <ModalBody>
        <div className="h3 font-weight-bold">Ops! não temos integração automática ainda!</div>
        <div className="h6 font-weight-light mb-4">Precisamos que você pegue as credenciais de API manualmente.</div>
        <div>
          <p>1 - Clique <a href="https://www.mercadopago.com/mlb/account/credentials" target="blank">neste link</a></p>
          <p>2 - Se não estiver logado, faça login</p>
          <p>3 - Uma vez logado, clique no botão escrito "Checkout Basico", agora copie o client_id para o campo abaixo, e depois copie o "client_secret" para o campo abaixo</p>
        </div>
        {error !== "" && <Alert color="danger">
          {error}
        </Alert>}
        <div className="mb-2">
          <label>Client ID</label>
          <input id="client_id" className="form-control" value={clientId} onChange={(e) => setClientId(e.target.value)} />
        </div>
        <div>
          <label>Client Secret</label>
          <input id="client_secret" className="form-control" value={clientSecret} onChange={(e) => setClientSecret(e.target.value)} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isFetching} onClick={() => save()}>{isFetching ? 'Salvando...' : 'Salvar & Ativar'}</Button>
        <Button color="dark" disabled={isFetching} onClick={() => setModal(false)}>Fechar</Button>
      </ModalFooter>
    </Modal>
  </div>
}