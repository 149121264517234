import React, {useState, useEffect} from 'react';
import {Card, CardBody, Table, Row, Col} from 'reactstrap';

import api from '../../services/api';
import {getStoreToken} from '../../services/auth';
import {getMethod, getStatus} from '../../helpers/enums';

import Loader from '../../components/Loader';
import SaleConfig from '../../components/SaleConfig';

export default function Sale({match}) {
  const [loading, setLoading] = useState(true);
  const [productPurchaseInfoResumes, setProductPurchaseInfoResumes] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [client, setClient] = useState([]);

  useEffect(() => {
    async function get() {
      await api.get(`/purchase/${match.params.id}`, {
        headers: {
          "Authorization": `Bearer ${getStoreToken()}`,
        },
      }).then(result => {
        var {productPurchaseInfoResumes, client, transaction} = result.data;

        setProductPurchaseInfoResumes(productPurchaseInfoResumes);
        setClient(client);
        setTransaction(transaction);

        setLoading(false);
      });
    }

    get();
  }, [match]);

  return (
    <>
      <Card className="mb-5">
        <Loader show={loading} />
        <CardBody>
          <h1 className="h3 font-weight-light m-0 mb-4">
            Compra #{match.params.id}
          </h1>
          <br />
          <Row>
            <Col md="5">
              <div className="h5 font-weight-light mb-3">Cliente</div>
              <p>
                <b>Nome</b>: {client.name}<br />
                <b>E-mail</b>: {client.email} 
              </p>
            </Col>
            <Col md="7">
              <div className="h5 font-weight-light mb-3">Dados da transação</div>
              <p>
                <b>Código de Transação</b>: {transaction.externalCode}<br />
                <b>Referência</b>: {transaction.externalReference} <br />
                <b>Gateway</b>: {transaction.paymentGateway} <br />
                <b>Forma de Pagamento</b>: {getMethod(transaction.paymentMethod)} <br />
                <b>Status</b>: {getStatus(transaction.paymentStatus)}
              </p>
            </Col>
          </Row>
          <br />
          <div className="h5 font-weight-light mb-3">Carrinho</div>
          <Table>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Configurações</th>
                <th>Produto</th>
                <th>Preço</th>
                <th>Qnt</th>
                <th>Desconto</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {productPurchaseInfoResumes.map(e => <SaleConfig key={e.id} data={e} />)}
            </tbody>
          </Table>
          <br />
        </CardBody>
      </Card>
    </>
  );
}
